<div class="edit-student-modal">
   <div class="edit-student-title">
      <h4>Edit student</h4>
   </div>
   <form [formGroup]="studentEdit">
      <div class="fieldContainer">
         <div class="inside-inputs">
            <label for="studentName">Name</label>
            <input id="studentName" type="text" formControlName="studentName" />
            <div *ngIf="this.emailExists" class="invalid">
               Email already exists, please try another one.*
            </div>
            <div
               *ngIf="
                  studentEdit.controls.studentName.touched &&
                  studentEdit.controls.studentName.invalid
               "
               class="invalid"
            >
               Full name is required
            </div>
            <div
               *ngIf="studentEdit.controls.studentName.errors?.minlength"
               class="invalid"
            >
               Full name should contain at least 3 characters.
            </div>
            <label for="Email">Email</label>
            <input
               id="Email"
               type="email"
               formControlName="studentEmail"
            />
            <div
               *ngIf="
                  studentEdit.controls.studentEmail.touched &&
                  studentEdit.controls.studentEmail.invalid
               "
               class="invalid"
            >
               Student Email is required*
            </div>
            <div
               *ngIf="studentEdit.controls.studentEmail.errors?.email"
               class="invalid"
            >
               Student Email is not valid!
            </div>
            <!-- <label for="PhoneNumber">Phone Number</label>
            <input
               id="PhoneNumber"
               type="text"
               formControlName="studentPhoneNumber"
            />
            <div
               *ngIf="
                  studentEdit.controls.studentPhoneNumber.touched &&
                  studentEdit.controls.studentPhoneNumber.invalid
               "
               class="invalid"
            >
               Student Contact number is required*
            </div>
            <div
               *ngIf="studentEdit.controls.studentPhoneNumber.errors?.minlength"
               class="invalid"
            >
               Student Contact number should contain at least 6 characters!
            </div> -->

            <label for="IndexId">Student ID</label>
            <input
               id="IndexId"
               type="text"
               formControlName="studentIndexID"
               (ngModelChange)="validateIndex()"
            />
            <div *ngIf="this.indexExists" class="invalid">
               Student ID already exists*
            </div>
            <div
               *ngIf="
                  studentEdit.controls.studentIndexID.touched &&
                  studentEdit.controls.studentIndexID.invalid
               "
               class="invalid"
            >
               Student ID is required*
            </div>
            <div
               *ngIf="studentEdit.controls.studentIndexID.errors?.minlength"
               class="invalid"
            >
               Student ID should contain at least 3 characters!
            </div>

            <label for="title">Faculty</label>
            <select
               formControlName="studentFaculty"
               (change)="facultyChanged()"
            >
               <ng-container *ngFor="let faculty of this.faculties">
                  <option value="{{ faculty.id }}" selected>
                     {{ faculty.name }}
                  </option>
               </ng-container>
            </select>
            <div
               *ngIf="
                  studentEdit.controls.studentFaculty.touched &&
                  studentEdit.controls.studentFaculty.invalid
               "
               class="invalid"
            >
               Student Faculty is required*
            </div>
            <label for="title">Department</label>
            <select formControlName="studentDepartment">
               <ng-container *ngFor="let department of this.departments">
                  <option value="{{ department.id }}" selected>
                     {{ department.name }}
                  </option>
               </ng-container>
            </select>
            <div
               *ngIf="
                  studentEdit.controls.studentDepartment.touched &&
                  studentEdit.controls.studentDepartment.invalid
               "
               class="invalid"
            >
               Student Department is required*
            </div>
            <label for="title">Status</label>
            <select formControlName="studentStatus">
               <option value="1">Active</option>
               <option value="0">Inactive</option>
            </select>
         </div>
         <div class="buttons">
            <a class="delete" (click)="delete()">Delete</a>
            <button
               class="save"
               [disabled]="
                  studentEdit.invalid || this.emailExists || this.indexExists
               "
               [ngClass]="{
                  disable:
                     studentEdit.invalid || this.emailExists || this.indexExists
               }"
               type="submit"
               (click)="update()"
            >
               Confirm
            </button>
         </div>
      </div>
   </form>
</div>
<div id="overlay"></div>
