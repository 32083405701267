import { Component, OnInit } from '@angular/core';
import {
   FormControl,
   FormBuilder,
   FormGroup,
   Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { Store } from '@ngrx/store';
import * as individualAdministratorActions from '../../state/action/individualAdministrator.actions';

@Component({
   selector: 'app-invite-student',
   templateUrl: './invite-student.component.html',
   styleUrls: ['./invite-student.component.scss'],
})
export class InviteStudentComponent implements OnInit {
   /**
    * Form group used to get user input
    */
   studentInvite: FormGroup;
   languages = [
      {
         text: 'English',
         value: 'en',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Albanian',
         value: 'sq',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'German',
         value: 'de',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Italian',
         value: 'it',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'French',
         value: 'fr',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Spanish',
         value: 'es',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Greek',
         value: 'el',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Czech',
         value: 'cs',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Turkish',
         value: 'tr',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Slovak',
         value: 'sk',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Lithuanian',
         value: 'lt',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Latvian',
         value: 'lv',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Polish',
         value: 'pl',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Serbian',
         value: 'sr',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Macedonian',
         value: 'mk',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Portuguese',
         value: 'pt',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Dutch',
         value: 'nl',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Russian',
         value: 'ru',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Bulgarian',
         value: 'bg',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Hungarian',
         value: 'hu',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Romanian',
         value: 'ro',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Slovenian',
         value: 'sl',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Swedish',
         value: 'sv',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Finnish',
         value: 'fi',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Croatian',
         value: 'hr',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Bosnian',
         value: 'bs',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
   ];
   constructor(
      private fb: FormBuilder,
      private userService: UserService,
      public dialogRef: MatDialogRef<InviteStudentComponent>,
      private store: Store
   ) {}

   ngOnInit(): void {
      this.studentInvite = this.fb.group({
         studentName: new FormControl('', Validators.required),
         studentEmail: new FormControl('', Validators.required),
         documentLanguage: new FormControl('', Validators.required),
         translateTo: new FormControl('', Validators.required),
      });
   }

   /**
    * Method use to get form controls.
    */
   get form() {
      return this.studentInvite.controls;
   }

   languageSelected(type) {
      if (type === 1) {
         for (let language of this.languages) {
            language.selectedDocLanguage = false;
            if (language.value === this.form.documentLanguage.value) {
               language.selectedDocLanguage = true;
            }
         }
         if (!this.form.translateTo.value) {
            this.form.translateTo.setValue('0');
         }
      } else {
         for (let language of this.languages) {
            language.selectedTranslateLanguage = false;
            if (language.value === this.form.translateTo.value) {
               language.selectedTranslateLanguage = true;
            }
         }
      }
   }

   update() {
      this.store.dispatch(
         individualAdministratorActions.inviteStudent({
            studentName: this.form.studentName.value,
            studentEmail: this.form.studentEmail.value,
            documentLanguage: this.form.documentLanguage.value,
            translateTo: this.form.translateTo.value,
         })
      );

      this.dialogRef.close();
   }
}
