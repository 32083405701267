<div class="cards">
   <div class="shadowBorder">
      <img src="../../../assets/images/newIcons/6.Thesis_Color.png" alt=""
      />
      <div>
         Total Theses
         <span>
            {{
               thesisInformation?.bachelorThesis +
                  thesisInformation?.masterThesis +
                  thesisInformation?.phdThesis
            }}
         </span>
      </div>
   </div>
   <div class="shadowBorder">
      <img src="../../../assets/images/newIcons/7.Thesis_Bachelor_Color.png" alt=""/>
      <div>
         Bachelor theses
         <span>{{ thesisInformation?.bachelorThesis || 0 }}</span>
      </div>
   </div>
   <div class="shadowBorder">
      <img src="../../../assets/images/newIcons/8.Thesis_Master_Color.png" alt=""/>
      <div>
         Master theses
         <span>{{ thesisInformation?.masterThesis || 0 }}</span>
      </div>
   </div>
   <div class="shadowBorder">
      <img src="../../../assets/images/newIcons/9.Thesis_PHD_Color.png" alt=""/>
      <div>
         Phd theses
         <span> {{ thesisInformation?.phdThesis || 0 }}</span>
      </div>
   </div>
</div>

<h3
   *ngIf="
      !(
         thesisInformation?.totalCreatedThesis == 0 &&
         facultyId == '-1' &&
         departmentId == '-1' &&
         name == undefined &&
         level == '-1'
      )
   "
   class="filtersContainer"
>
   <h4>Theses</h4>
   <div class="selectors">
      <div>
         <select [(ngModel)]="facultyId" (change)="selectedFaculty()">
            <option value="-1" selected disabled>
               Filter by faculty
            </option>
            <ng-container *ngFor="let faculty of this.faculties">
               <option value="{{ faculty.id }}">
                  {{ faculty.name }}
               </option>
            </ng-container>
            <option value="">All</option>
         </select>
      </div>
      <div>
         <select
            disabled
            *ngIf="facultyId == '-1' || facultyId == ''"
            name=""
            id=""
         >
            <option value="">Select faculty first</option>
         </select>
         <select
            *ngIf="facultyId !== '-1' && facultyId !== ''"
            [(ngModel)]="departmentId"
            (change)="filterThesis()"
         >
            <option value="-1" selected disabled>
               Filter by department
            </option>
            <ng-container *ngFor="let department of this.departments">
               <option value="{{ department.id }}">
                  {{ department.name }}
               </option>
            </ng-container>
            <option value="">All</option>
         </select>
         <select [(ngModel)]="level" (change)="filterThesis()">
            <option value="-1" selected disabled>Filter by level</option>
            <option value="bachelor">Bachelor</option>
            <option value="master">Master</option>
            <option value="phd">Phd</option>
            <option value="">All</option>
         </select>
      </div>
      <div>
         <input
            placeholder="Search by name"
            [(ngModel)]="name"
            (ngModelChange)="titleFilter()"
            type="text"
            name="searchByName"
         />
      </div>
      <button class="reportBtn" (click)="resetFilter()">Reset</button>
   </div>
</h3>

<main>
   <div
      *ngIf="thesisInformation?.totalCreatedThesis != 0"
      class="scroll white-bg"
   >
      <table>
         <tr>
            <th class="bold">Title</th>
            <th class="bold">Faculty</th>
            <th class="bold">Department</th>
            <th class="bold">Student</th>
            <th class="bold">Professor</th>
            <th class="bold">Level</th>
         </tr>
         <ng-container
            *ngFor="
               let thesis of thesisInformation?.createdThesis
                  | paginate
                     : {
                          itemsPerPage: 5,
                          currentPage: page,
                          totalItems: thesisInformation?.totalCreatedThesis
                       }
            "
         >
            <tr class="shadow">
               <td>
                  {{ thesis.name }}
               </td>

               <td>
                  {{ thesis.professor.Faculty.name }}
               </td>

               <td>
                  {{ thesis.professor.Department.name }}
               </td>

               <td>
                     {{ thesis.student.name }}
               </td>

               <td>
                     {{ thesis.student.name }}
               </td>
               <td>
                     {{ thesis.level }}
               </td>
            </tr>
         </ng-container>
      </table>
      <pagination-controls
         class="num"
         (pageChange)="changePage($event)"
         responsive="true"
         previousLabel=""
         nextLabel=""
      ></pagination-controls>
   </div>
   <div
      class="zero-state"
      *ngIf="
         thesisInformation?.totalCreatedThesis == 0 &&
         facultyId == '-1' &&
         departmentId == '-1' &&
         name == undefined &&
         level == '-1'
      "
   >
      <img src="../../assets/images/icona-image.png" alt="" />
      <h5 class="bold">There are no thesis submitted yet.</h5>
   </div>
   <div
      class="zeroState"
      *ngIf="
         thesisInformation?.totalCreatedThesis == 0 &&
         (name != undefined ||
            facultyId != '-1' ||
            departmentId != '-1' ||
            level != '-1')
      "
   >
      No thesis found
   </div>
</main>
