// *** Angular
import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';

// Packakges
import {
   ApexAxisChartSeries,
   ApexChart,
   ApexTitleSubtitle,
   ApexXAxis,
} from 'ng-apexcharts';
import { NgxSpinnerService } from 'ngx-spinner';

// *** Services
import { AdministratorStatisticsService } from 'src/app/services/administrator-statistics.service';

export type ChartOptions = {
   series: ApexAxisChartSeries;
   chart: ApexChart;
   xaxis: ApexXAxis;
   title: ApexTitleSubtitle;
   labels: string[];
};

@Component({
   selector: 'app-users-institutional',
   templateUrl: './users-institutional.component.html',
   styleUrls: ['./users-institutional.component.scss'],
})
export class UsersInstitutionalComponent implements OnInit {
   /**
    * Variabel used to store data that are shown on chart
    */
   institutionUsersData: any;
   /**
    * Variabel used to store options for chart
    */
   chartOptions = {
      series: [
         {
            name: 'Active students',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
         },
         {
            name: 'Registered students',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
         },
         {
            name: 'Unactive students',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
         },
      ],
      colors: ['#F44336', '#E91E63', '#9C27B0'],
      chart: {
         height: 350,
         type: 'line',
         zoom: {
            enabled: true,
         },
      },
      dataLabels: {
         enabled: true,
         style: {
            colors: ['#F44336', '#E91E63', '#9C27B0'],
         },
      },
      title: {
         text: 'Students',
         align: 'center',
      },
      xaxis: {
         categories: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
         ],
      },
      grid: {
         borderColor: '#f1f1f1',
      },
   };
   /**
    * Variabel used to store current year
    */
   year: number;
   constructor(
      private statistics: AdministratorStatisticsService,
      private spinner: NgxSpinnerService
   ) {}

   ngOnInit(): void {
      this.year = new Date().getFullYear();

      this.statistics
         .institutionUsersStatistics(this.year)
         .pipe(first())
         .subscribe(
            (data) => {
               this.institutionUsersData = data;

               this.chartOptions = {
                  series: [
                     {
                        name: 'Active students',
                        data: data.usersActivated,
                     },
                     {
                        name: 'Registered students',
                        data: data.usersRegistered,
                     },
                     {
                        name: 'Unactive students',
                        data: data.usersUnActivated,
                     },
                  ],
                  colors: ['#F44336', '#E91E63', '#9C27B0'],
                  chart: {
                     height: 350,
                     type: 'line',
                     zoom: {
                        enabled: true,
                     },
                  },
                  dataLabels: {
                     enabled: true,
                     style: {
                        colors: ['#F44336', '#E91E63', '#9C27B0'],
                     },
                  },
                  title: {
                     text: 'Students',
                     align: 'center',
                  },
                  xaxis: {
                     categories: [
                        'January',
                        'February',
                        'March',
                        'April',
                        'May',
                        'June',
                        'July',
                        'August',
                        'September',
                        'October',
                        'November',
                        'December',
                     ],
                  },
                  grid: {
                     borderColor: '#f1f1f1',
                  },
               };
            },
            (error) => {
               console.log('error', error);
            }
         );
   }
   /**
    * Function is used to filter statitistics by year
    * @param value
    */
   filterByYear(value) {
      this.spinner.show();
      this.year = parseInt(value);
      this.statistics
         .institutionUsersStatistics(this.year)
         .pipe(first())
         .subscribe(
            (data) => {
               this.institutionUsersData = data;
               this.chartOptions = {
                  series: [
                     {
                        name: 'Active students',
                        data: this.institutionUsersData.activated,
                     },
                     {
                        name: 'Registered students',
                        data: this.institutionUsersData.registered,
                     },
                     {
                        name: 'Unactive students',
                        data: this.institutionUsersData.unActivated,
                     },
                  ],
                  colors: ['#F44336', '#E91E63', '#9C27B0'],
                  chart: {
                     height: 350,
                     type: 'line',
                     zoom: {
                        enabled: true,
                     },
                  },
                  dataLabels: {
                     enabled: true,
                     style: {
                        colors: ['#F44336', '#E91E63', '#9C27B0'],
                     },
                  },
                  title: {
                     text: 'Students',
                     align: 'center',
                  },
                  xaxis: {
                     categories: [
                        'January',
                        'February',
                        'March',
                        'April',
                        'May',
                        'June',
                        'July',
                        'August',
                        'September',
                        'October',
                        'November',
                        'December',
                     ],
                  },
                  grid: {
                     borderColor: '#f1f1f1',
                  },
               };
               this.spinner.hide();
            },
            (error) => {
               this.spinner.hide();
               console.log('error', error);
            }
         );
   }
}
