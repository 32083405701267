<h4 class="bold w-87">AI Content Detector</h4>
<main class="shadowBorder textDetectorContainer">
    <div class="header">
        <h2>AI Content Detector (Updated version)</h2>
        <p>Use our AI Content Detector for free to check if the text is AI-generated - now with higher accuracy!</p>
    </div>
    
    <div class="content">
        <div class="textContainer">
            <h4>Text box</h4>
            <textarea placeholder="Paste or write some text here" [(ngModel)]="text" name="" id="" cols="30" rows="10"></textarea>
            <div [ngStyle]="{'color': text?.split(' ').length > 3000?'red':'unset'}" style="display: flex;     align-items: flex-end; justify-content: right; margin-top: 10px; flex-direction: column;"> {{text?.split(' ').length?text?.split(' ').length:0}}/3000<span style="font-size: 0.8rem;" *ngIf="text?.split(' ').length > 3000">Only the first 3,000 words will be checked</span></div>
            <div>
            <button (click)="detectText()" class="checkBtn">Check</button>
        </div>
        </div>
    <div class="dataConatiner">
        <h4>Results</h4>
        <div class="border">
        <div class="resultsConatiner">
            <h4 class="contentIndex">AI Content Index<i
             class="fas fa-info-circle"
             matTooltipPosition="after"
             matTooltipClass="tooltipCustomCss"
             matTooltip="AI Content Index is in the beta phase, and results may vary and may not always be accurate. Results are provided for informational purposes only and are not intended to be relied upon for any specific purpose."
          ></i></h4>
            <div class="chartContainer">
                <div [ngStyle]="{'left.%': fakeProbability}" class="pointer">
                    <span>{{fakeProbability}}%</span>
                    <img  src="../../../assets/images/Asset_2.png" alt="">
                </div>
  
                <img src="../../../assets/images/Results-Graph.png" alt="">
            </div>
            <!-- <div id="chart">
                <apx-chart
                  [series]="chartOptions.series"
                  [chart]="chartOptions.chart"
                  [plotOptions]="chartOptions.plotOptions"
                  [labels]="chartOptions.labels"
                  [fill]="chartOptions.fill"
                ></apx-chart>
              </div> -->
              <div    [ngClass]="{
                'redBorder':
                fakeProbability > 200/3 && hasText,
                'orangeBorder':
                fakeProbability > 100/3 && fakeProbability <= 200/3 && hasText,
                'greenBorder':
                fakeProbability <= 100/3 && hasText
             }"  class="description">
                {{description}}</div>
        </div>
        <div class="rates">
            <span>Are you satisfied with the results?</span> <i [ngClass]="{ likedClicked: likedClicked }" (click)="rate('like')" class="far fa-thumbs-up"></i><i [ngClass]="{ dislikeClicked: dislikeClicked }"  (click)="rate('dislike')" class="far fa-thumbs-down"></i>
        </div>
        <h4 class="disclaimer">Disclaimer</h4>
        <div >
            <ul>
                <li>The AI Content Detector v2.0 is based to check on a deeper level to give more precise results</li>
                <li>The result might take a bit longer to display as the text is checked more thoroughly</li>
                <li>The AI Content Detector v2.0 is now able to analyze up to 3,000 words (compared to the 1,000 words of the first version)</li>
                <li>At this point, the AI Content Detector only works for the English language.</li>
            </ul>

        </div>
        </div>
    </div>
</div>
<div class="processing" *ngIf="processing">
    Hang tight while we analyze your text.<br/> This shouldn't take too long.
    <mat-spinner></mat-spinner>
</div>
</main>

