<div class="textEditorModal">
    <i class="fas fa-times-circle" (click)="closeModal()"></i>
    <h4> <b>Feature Announcement</b> <span> | December 19, 2022</span></h4>
    <div class="modalImage">
        <div>

        </div>
        <img src="../../../assets/images/01.jpg" alt="">
    </div>
    <h2>AI Content Detector Benefits</h2>
    <ul>
        <li><b>Academic Integrity:</b> Our AI Content Detector can identify text written by AI which helps prevent students from using AI to generate essays or other assignments.</li>
        <li><b>Accountability:</b> Using our AI Content Detector to identify text written by AI can help hold students accountable for meeting academic integrity and originality standards.</li>
        <li><b>Efficiency:</b> Automating the process of identifying AI-written content helps educators become more efficient in making sure integrity and originality standards are being met.</li>
    </ul>
    <div class="modalFooter">

        <button class="submit" routerLink="/individual/detector" (click)="closeModal()">Try it</button>
    </div>
</div>