<div class="contact-us-2">
   <main>
      <div class="flex column">
         <div class="flex email border">
            <div class="flex">
               <i class="far fa-envelope"></i>
               <div class="flex-column">
                  <h5 class="bold">{{ 'general.email' | translate }}</h5>
                  <h6>
                     Submit a ticket
                  </h6>
               </div>
            </div>
            <button (click)="emailUs()" class="blue-btn blue-background">
               {{ 'help_center.contact_us.email_us' | translate }}
            </button>
         </div>
         <!-- <div class="flex email border">
            <div class="flex">
               <i class="fab fa-facebook-square"></i>
               <div class="flex-column">
                  <h5 class="bold">
                     {{ 'help_center.contact_us.facebook' | translate }}
                  </h5>
                  <h6>
                     {{ 'help_center.contact_us.monday_to_friday' | translate }}
                  </h6>
               </div>
            </div>
            <button (click)="openFacebook()" class="blue-btn blue-background">
               {{ 'help_center.contact_us.like_our_page' | translate }}
            </button>
         </div> -->
         <div class="flex email border">
            <div class="flex">
               <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  version="1.1"
                  id="Capa_1"
                  x="0px"
                  y="0px"
                  viewBox="0 0 512.001 512.001"
                  style="enable-background: new 0 0 512.001 512.001"
                  xml:space="preserve"
                  width="35px"
               >
                  <g>
                     <g>
                        <g>
                           <path
                              fill="#00bbff"
                              d="M468.53,306.575c-4.14-10.239-15.798-15.188-26.038-11.046c-10.241,4.14-15.187,15.797-11.047,26.038L455,379.833     l-69.958-30.839c-5.064-2.232-10.827-2.267-15.917-0.095c-23.908,10.201-49.52,15.373-76.124,15.373     c-107.073,0-179-83.835-179-162.136c0-89.402,80.299-162.136,179-162.136s179,72.734,179,162.136     c0,6.975-0.65,15.327-1.781,22.913c-1.63,10.925,5.905,21.102,16.83,22.732c10.926,1.634,21.103-5.905,22.732-16.83     c1.431-9.59,2.219-19.824,2.219-28.815c0-54.33-23.006-105.308-64.783-143.543C405.936,20.809,351.167,0,293.001,0     S180.067,20.809,138.784,58.592c-37.332,34.168-59.66,78.516-63.991,126.335C27.836,216.023,0.001,265.852,0.001,319.525     c0,33.528,10.563,65.34,30.67,92.717L1.459,484.504c-3.051,7.546-1.224,16.189,4.621,21.855     c3.809,3.694,8.828,5.642,13.925,5.642c2.723-0.001,5.469-0.556,8.063-1.7l84.229-37.13c21.188,7.887,43.585,11.88,66.703,11.88     c0.5,0,0.991-0.039,1.482-0.075c33.437-0.253,65.944-9.048,94.098-25.507c25.218-14.744,45.962-34.998,60.505-58.917     c14.199-2.55,28.077-6.402,41.547-11.551l107.301,47.3c2.595,1.143,5.34,1.7,8.063,1.7c5.097-0.001,10.117-1.949,13.926-5.642     c5.845-5.666,7.672-14.308,4.621-21.855L468.53,306.575z M179.002,445c-0.273,0-0.539,0.03-0.81,0.041     c-20.422-0.104-40.078-4.118-58.435-11.95c-5.089-2.173-10.852-2.138-15.916,0.095l-46.837,20.646l15.109-37.375     c2.793-6.909,1.512-14.799-3.322-20.47c-18.835-22.097-28.79-48.536-28.79-76.462c0-31.961,13.445-62.244,36.969-85.206     c7.324,39.925,27.989,78.117,59.162,108.119c38.791,37.333,90.101,58.961,145.506,61.565     C255.626,429.608,218.402,445,179.002,445z"
                           />
                           <circle
                              fill="#00bbff"
                              cx="292.001"
                              cy="203"
                              r="20"
                           />
                           <circle
                              fill="#00bbff"
                              cx="372.001"
                              cy="203"
                              r="20"
                           />
                           <circle
                              fill="#00bbff"
                              cx="212.001"
                              cy="203"
                              r="20"
                           />
                        </g>
                     </g>
                  </g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
               </svg>
               <div class="flex-column">
                  <h5 class="bold">
                     {{ 'help_center.contact_us.live_chat' | translate }}
                  </h5>
                  <h6>
                     Get instant support
                  </h6>
               </div>
            </div>
            <button (click)="startChat()" class="blue-btn blue-background">
               {{ 'help_center.contact_us.start_chat' | translate }}
            </button>
         </div>
      </div>
      <h4 class="bold">
         {{ 'help_center.contact_us.start_conversation' | translate }}
      </h4>
      <p>{{ 'help_center.contact_us.start_conversation_text' | translate }}.</p>
      <ng-container [formGroup]="contactUsForm">
         <div class="flex column">
            <div class="input">
               <h5>{{ 'general.full_name' | translate }}</h5>
               <input
                  [readonly]="true"
                  formControlName="fullName"
                  type="text"
                  disabled
               />
            </div>
            <div
               *ngIf="
                  formControls.fullName.touched && formControls.fullName.invalid
               "
               class="alert alert-danger"
            >
               <div
                  class="invalid"
                  *ngIf="formControls.fullName.errors.required"
               >
                  {{ 'validators.enter_email' | translate }}
               </div>
            </div>
            <div class="input">
               <h5>{{ 'general.email' | translate }}</h5>
               <input
                  [readonly]="true"
                  formControlName="email"
                  type="text"
                  disabled
               />
            </div>
            <div
               *ngIf="formControls.email.touched && formControls.email.invalid"
               class="alert alert-danger"
            >
               <div class="invalid" *ngIf="formControls.email.errors.required">
                  {{ 'validators.enter_email' | translate }}
               </div>
            </div>
            <!-- <div class="validation input">
               <div>
                  <h5>{{ 'general.phone' | translate }}</h5>
                  <input formControlName="phoneNumber" type="text" />
               </div>
               <div
                  *ngIf="
                     formControls.phoneNumber.touched &&
                     formControls.phoneNumber.invalid
                  "
                  class="alert alert-danger"
               >
                  <div
                     class="invalid"
                     *ngIf="formControls.phoneNumber.errors.required"
                  >
                     Your phone number is required.
                  </div>
               </div>
            </div> -->
            <div class="validation input">
               <div>
                  <h5>Request type</h5>
                  <select formControlName="request" name="" id="">
                     <option value="refund">Refund</option>
                     <option value="deleteAccount">Delete Account</option>
                     <option value="help">Help</option>
                  </select>
               </div>
               <div
                  *ngIf="
                     formControls.request.touched &&
                     formControls.request.invalid
                  "
                  class="alert alert-danger"
               >
                  <div
                     class="invalid"
                     *ngIf="formControls.request.errors.required"
                  >
                     Request type is required*
                  </div>
               </div>
            </div>
         </div>
         <h5>{{ 'general.message' | translate }}</h5>
         <textarea
            formControlName="message"
            name=""
            id=""
            cols="30"
            rows="7"
            placeholder="Enter your message here ..."
         >
         </textarea>
         <div
            *ngIf="formControls.message.touched && formControls.message.invalid"
            class="alert alert-danger"
         >
            <div class="invalid" *ngIf="formControls.message.errors.required">
               Message can't be empty
            </div>
         </div>
         <div class="center">
            <button
               *ngIf="contactUsForm.valid"
               (click)="sendMessage()"
               class="submit"
            >
               Send
            </button>
            <button
               *ngIf="contactUsForm.invalid"
               (click)="showToasterWarning('Please re-check your info!')"
               class="submit"
            >
               Send
            </button>
         </div>
      </ng-container>
   </main>
</div>
