<div class="documents-2 shadow white-bg">
   <div
      *ngIf="
         !(
            this.totalSubjects == 0 &&
            subjectName == undefined &&
            facultyId == '-1' &&
            departmentId == '-1' &&
            professor == '-1'
         )
      "
      class="filtersContainer"
   >
      <h4>Overview</h4>
      <div class="selectors">
         <div>
            <select [(ngModel)]="facultyId" (change)="selectedFaculty()">
               <option value="-1" selected disabled>
                  Filter by faculty
               </option>
               <option
                  *ngFor="let faculty of this.faculties"
                  value="{{ faculty.id }}"
               >
                  {{ faculty.name }}
               </option>
               <option value="">All</option>
            </select>
         </div>
         <div>
            <select
               disabled
               *ngIf="facultyId == '-1' || facultyId == ''"
               name=""
               id=""
            >
               <option value="">Select faculty first</option>
            </select>
            <select
               *ngIf="facultyId !== '-1' && facultyId !== ''"
               [(ngModel)]="departmentId"
               (change)="selectedDepartment()"
            >
               <option value="-1" selected disabled>
                  Filter by department
               </option>
               <ng-container *ngFor="let department of this.departments">
                  <option value="{{ department.id }}">
                     {{ department.name }}
                  </option>
               </ng-container>
               <option value="">All</option>
            </select>
         </div>

         <div>
            <select [(ngModel)]="professor" (change)="filterByProfessor()">
               <option value="-1" selected disabled>
                  Filter by professor
               </option>
               <ng-container *ngFor="let professor of this.professors">
                  <option value="{{ professor.id }}">
                     {{ professor.name }}
                  </option>
               </ng-container>

               <option value="">All</option>
            </select>
         </div>

         <div>
            <input
               placeholder="Search by name"
               [(ngModel)]="subjectName"
               (ngModelChange)="titleFilter()"
               type="text"
               name="searchByName"
            />
         </div>
         <div>
            <input
               placeholder="Search by student"
               [(ngModel)]="studentName"
               (ngModelChange)="titleFilter()"
               type="text"
               name="searchByStudentName"
            />
         </div>
         <button class="reportBtn" (click)="resetFilter()">Reset</button>
      </div>
   </div>

   <div
      *ngIf="this.totalSubjects != 0"
      class="tableWithExpandedInfoDepartments tablewithRatios"
   >
      <ng-container
         *ngFor="
            let subject of this.subjects
               | paginate
                  : {
                       itemsPerPage: 5,
                       currentPage: this.page,
                       totalItems: this.totalSubjects
                    };
            let i = index
         "
      >
         <div>
            <div>
               <h4 class="blueText bold">{{ subject.name }}</h4>
               <div>
                  <div>
                     <span>Professors</span>
                     <span>Students</span>
                     <span>Assignments</span>
                     <span>Submissions</span>
                     <span>Document language<br/> similarity</span>
                     <span>Translation language<br/> similarity</span>
                  </div>
                  <div>
                     <span
                        (click)="filterProfessors(subject.name)"
                        class="bold clickable"
                        >{{ subject.professors }}</span
                     >
                     <span
                        (click)="filterStudents(subject.name)"
                        class="bold clickable"
                        >{{ subject.students }}</span
                     >
                     <span
                        (click)="filterAssignment(subject.name)"
                        class="bold clickable"
                        >{{ subject.assignments }}</span
                     >
                     <span
                        (click)="filterSubmissions(subject.name)"
                        class="bold clickable"
                        >{{ subject.submissions._count }}</span
                     >
                     <span class="bold">{{
                        subject.submissions._avg.originalPercentage?.toFixed(
                           2
                        ) || 0
                     }}</span>
                     <span class="bold">{{
                        subject.submissions._avg.translatedPercentage?.toFixed(
                           2
                        ) || 0
                     }}</span>
                  </div>
               </div>
               <a
                  *ngIf="expandInfo !== i"
                  class="blueText underline vold"
                  (click)="expand(i)"
                  >Expand</a
               >
               <a
                  *ngIf="expandInfo === i"
                  class="blueText underline vold"
                  (click)="expand(i)"
                  >Collapse</a
               >
            </div>
            <div
               [ngClass]="{ expandedInfo: expandInfo == i }"
               class="moreInfo"
               style="grid-template: none !important"
            >
               <div class="details">
                 <span>
                 Most used language:
                 <b>{{ subject.language[0] }}</b></span
                 >
                 <span>
                 High similarity submissions:
                 <b>{{ subject.highSimilaritySubmissions }}</b></span
                 >
                 <span>
                 Medium similarity submissions:
                 <b>{{ subject.mediumSimilaritySubmissions }}</b></span
                 >
                 <span>
                 Low similarity submissions:
                 <b>{{ subject.lowSimilaritySubmissions }}</b></span
                 >
               </div>
               <div class="ratios">
                 Ratios
               </div>
               <div class="details">
                  <span>
                     Professor-to-Student:
                     <b
                        >{{ subject.professors }} : {{
                           subject.students
                        }}</b
                     >
                     </span>
                     <span>
                     Professor-to-Submission:<b
                        > {{ subject.professors }} : {{
                           subject.submissions._count
                        }}</b
                     >
                     </span>
                     <span>
                     Student-to-Submission:
                     <b
                        >{{ subject.students }} : {{
                           subject.submissions._count }}</b
                     ></span
                     >
               </div>
            </div>
         </div>
      </ng-container>
   </div>
   <div
      class="zeroState"
      *ngIf="
         this.totalSubjects == 0 &&
         (subjectName != undefined ||
            facultyId != '-1' ||
            departmentId != '-1' ||
            professor != '-1')
      "
   >
      No subjects found
   </div>
   <pagination-controls
      *ngIf="this.totalSubjects != 0"
      class="num"
      (pageChange)="changePage($event)"
      responsive="true"
      previousLabel=""
      nextLabel=""
   ></pagination-controls>
   <div
      class="zero-state"
      *ngIf="
         this.totalSubjects == 0 &&
         subjectName == undefined &&
         facultyId == '-1' &&
         departmentId == '-1' &&
         professor == '-1'
      "
   >
      <img src="../../assets/images/icona-image.png" alt="" />
      <h5 class="bold" style="z-index: 1;">There are no subjects registered in your institution. 
         <a 
           routerLink ="/administrator/register-module/course-register" 
           routerLinkAction="active">Click here</a>
          to begin registering your first subject.</h5>
   </div>
</div>
