import { getCurrentUserSubmissionsGraphData } from './../state/selectors/submissions.selectors';
import { Store } from '@ngrx/store';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as submissionsActions from '../state/actions/submissions.actions';
import * as userActions from '../../authModule/state/actions/authentication.actions';
import { getCurrentUserSubmissionsDetails } from '../state/selectors/submissions.selectors';
import { getCurrentUser } from 'src/app/authModule/state/authentication.selectors';
import { environment } from '../../../environments/environment';
import { StatisticsService } from '../../services/statistics.service';
import { AuthService } from '../../services/auth.service';
import { saveAs } from 'file-saver';
import {
   ApexAxisChartSeries,
   ApexChart,
   ChartComponent,
   ApexDataLabels,
   ApexYAxis,
   ApexTitleSubtitle,
   ApexXAxis,
   ApexFill,
   ApexTooltip,
   ApexStroke,
} from 'ng-apexcharts';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { ExcelService } from '../../services/excel.service';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SubmissionsService } from 'src/app/services/submissions.service';

export type ChartOptions = {
   series: ApexAxisChartSeries;
   chart: ApexChart;
   xaxis: ApexXAxis;
   yaxis: ApexYAxis | ApexYAxis[];
   title: ApexTitleSubtitle;
   labels: string[];
   stroke: any; // ApexStroke;
   dataLabels: any; // ApexDataLabels;
   fill: ApexFill;
   tooltip: ApexTooltip;
};

export type creditsChartOptions = {
   series: ApexAxisChartSeries;
   chart: ApexChart;
   xaxis: ApexXAxis;
   stroke: ApexStroke;
   tooltip: ApexTooltip;
   dataLabels: ApexDataLabels;
};

/**
 * Individual Component Statistics
 */
@Component({
   selector: 'app-individual-statistics',
   templateUrl: './individual-statistics.component.html',
   styleUrls: ['./individual-statistics.component.scss'],
})
export class IndividualStatisticsComponent implements OnInit, OnDestroy {
   /**
    * Submission details selected from the store without subscribing used only in html.
    */
   submissionsDetails$;
   /**
    * Variable used to store state store
    */
   submissionsDetails;
   /**
    * Variable used to store submissions Statistics state, more specific: graph data
    */
   submissionsGraphData;
   /**
    * Current year
    */
   year = 2021;

   /**
    * List of years to show on dropdown
    */
   years = [
      { name: '2020', value: 2020 },
      { name: '2021', value: 2021 },
   ];
   /**
    * Submission details Subscriber
    */
   submissionsDetailsSub;
   /**
    * Submission Data Graph Subscriber
    */
   submissionsGraphSub;
   /**
    * Symbolizing the page of pagination
    */
   page: number = 1;
   /**
    * Symbolizing the page of transactions pagination
    
    */
   pageTransaction: number = 1;

   /**
    * Symbolizing the size of the page
    */
   pageSize: number = 5;

   /**
    * Representing tile model
    */
   title: string;
   /**
    * Filter for the submissions percentage
    */
   filter: number = 0;
   /**
    * Timer used for search delay
    */
   timer: any;

   /**
    * Used to show zero state and search result state if filter are active
    */
   isFilterActive: boolean = false;

   /**
    * Html credits chart element
    */
   @ViewChild('creditsChart') creditsChart: ChartComponent;
   /**
    * Configurations credit chart options
    */
   public creditsChartOptions: Partial<creditsChartOptions>;
   /**
    * Html Number of words and document chart element
    */
   @ViewChild('chart') chart: ChartComponent;
   /**
    * Configurations for number of words & documents chart options
    */
   public chartOptions: Partial<ChartOptions>;
   /**
    * Current used data.
    */
   currentUser: any;
   /**
    * User Transactions Data
    */
   transactions: any;
   /**
    * User paginated transactions
    */
   transactionsPagination: any;
   /**
    * Subscriber used to get current User details
    */
   currentUserDetailsSubscriber$: any;
   /**
    * Current user details
    */
   currentUserDetails: any;
   user$;
   user;
   reSubmission: boolean = false;
   /**
    * Component constructor
    * @param store
    * @param toastr
    * @param statisticsService
    * @param authService
    * @param excelService
    * @param router
    * @param spinner
    */
   constructor(
      private store: Store,
      private toastr: ToastrService,
      private statisticsService: StatisticsService,
      private authService: AuthService,
      private excelService: ExcelService,
      private router: Router,
      private spinner: NgxSpinnerService,
      private submissionService: SubmissionsService
   ) {
      this.chartOptions = {
         series: [
            {
               name: 'Nr of Words',
               type: 'column',
               data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
            {
               name: 'Documents',
               type: 'line',
               data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
            {
               name: 'Documents Deleted',
               type: 'line',
               data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
         ],
         chart: {
            height: 350,
            type: 'line',
         },
         stroke: {
            width: [0, 4],
         },
         title: {
            text: 'Documents and Nr. Words',
         },
         dataLabels: {
            enabled: true,
            enabledOnSeries: [1],
         },

         xaxis: {
            categories: [
               'January',
               'February',
               'March',
               'April',
               'May',
               'June',
               'July',
               'August',
               'September',
               'October',
               'November',
               'December',
            ],
         },
         yaxis: [
            {
               title: {
                  text: 'Words',
               },
            },
            {
               opposite: true,
               title: {
                  text: 'Documents',
               },
            },
         ],
      };

      this.creditsChartOptions = {
         series: [
            {
               name: 'Spend Credits',
               data: [31, 40, 28, 51, 42, 109, 100, 21, 21, 23, 23, 54],
               color: '#f26f87',
            },
            {
               name: 'Purchased Credits',
               data: [11, 32, 45, 32, 34, 12, 32, 46, 88, 65, 32, 52],
               color: '#3960D1',
            },
         ],
         chart: {
            height: 350,
            type: 'area',
         },
         dataLabels: {
            enabled: true,
         },
         stroke: {
            curve: 'smooth',
         },
         xaxis: {
            categories: [
               'January',
               'February',
               'March',
               'April',
               'May',
               'June',
               'July',
               'August',
               'September',
               'October',
               'November',
               'December',
            ],
            labels: {
               rotateAlways: false,
               style: {
                  colors: '#ABAFB3',
                  fontSize: '8px',
                  fontWeight: 'bold',
               },
            },
         },
         tooltip: {
            x: {
               format: 'dd/MM/yy HH:mm',
            },
         },
      };
   }
   /**
    * Method used to be called whenever the component is loaded and all the methods in it, will be triggered.
    */
   ngOnInit(): void {
      this.store.dispatch(submissionsActions.loadSubmissionsDetails());

      this.store.dispatch(userActions.currentUser());

      this.user$ = this.store.select(getCurrentUser).subscribe((data) => {
         if (data !== null) {
            this.user = data;
         }
      });

      this.submissionsDetails$ = this.store.select(
         getCurrentUserSubmissionsDetails
      );
      this.store.dispatch(
         submissionsActions.loadSubmissions({
            page: 1,
            filter: this.filter,
            pageSize: this.pageSize,
         })
      );
      this.store.dispatch(
         submissionsActions.loadSubmissionsGraph({ year: this.year })
      );
      this.submissionsDetailsSub = this.store
         .select(getCurrentUserSubmissionsDetails)
         .pipe()
         .subscribe((data) => {
            this.submissionsDetails = data;
         });
      if (this.user.institutionId === null) {
         this.submissionsGraphSub = this.store
            .select(getCurrentUserSubmissionsGraphData)
            .pipe()
            .subscribe((data) => {
               this.submissionsGraphData = data;
               if (this.submissionsGraphData !== null) {
                  this.chartOptions = {
                     series: [
                        {
                           name: 'Nr of Words',
                           type: 'column',
                           data: data.documentsWords,
                        },
                        {
                           name: 'Documents',
                           type: 'line',
                           data: data.documentsSubmitted,
                        },
                        {
                           name: 'Documents Deleted',
                           type: 'line',
                           data: data.documentsDeleted,
                        },
                     ],
                     chart: {
                        height: 400,
                        type: 'line',
                        toolbar: {
                           offsetX: 10,
                           offsetY: 2,
                           tools: {
                              reset: `<svg width="20" height="20" viewBox="0 0 315 315" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0)">
                        <path d="M307.475 141.686C300.582 137.088 291.268 138.95 286.673 145.843L286.227 146.512C280.801 72.405 218.777 13.767 143.309 13.767C64.288 13.768 0 78.056 0 157.077C0 236.098 64.288 300.386 143.309 300.386C154.355 300.386 163.309 291.432 163.309 280.386C163.309 269.34 154.355 260.386 143.309 260.386C86.344 260.385 40 214.041 40 157.077C40 100.113 86.344 53.768 143.309 53.768C196.107 53.768 239.774 93.583 245.88 144.764C241.067 138.773 232.394 137.342 225.88 141.686C218.987 146.282 217.126 155.596 221.722 162.488L251.424 207.029C254.929 212.285 260.632 215.423 266.679 215.423C272.726 215.423 278.428 212.284 281.931 207.029L311.633 162.488C316.228 155.595 314.367 146.282 307.475 141.686Z" fill="#6E8192"/>
                        </g>
                        <defs>
                        <clipPath id="clip0">
                        <rect width="314.154" height="314.154" fill="white"/>
                        </clipPath>
                        </defs>
                        </svg>
                        
                           `,
                              download: `<svg width="20" height="20" viewBox="0 0 305 272" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path d="M201.999 130.934C199.838 127.092 195.441 124.667 190.625 124.667H165.208V11.3332C165.208 5.07716 159.528 0 152.5 0C145.472 0 139.792 5.07716 139.792 11.3332V124.666H114.375C109.559 124.666 105.161 127.091 103.001 130.933C100.866 134.775 101.324 139.365 104.209 142.799L142.334 188.133C142.499 188.325 142.753 188.393 142.931 188.575C143.77 189.459 144.761 190.139 145.867 190.762C146.324 191.011 146.68 191.34 147.176 191.544C148.802 192.224 150.581 192.666 152.501 192.666C154.42 192.666 156.199 192.224 157.838 191.544C158.321 191.34 158.689 191.011 159.134 190.762C160.24 190.139 161.231 189.459 162.07 188.575C162.235 188.393 162.502 188.325 162.667 188.133L200.792 142.799C203.689 139.366 204.147 134.776 201.999 130.934Z" fill="#6E8192"/>
                           <path d="M292.292 170C285.264 170 279.584 175.077 279.584 181.333V215.333C279.584 234.078 262.478 249.333 241.459 249.333H63.5419C42.5225 249.333 25.4169 234.078 25.4169 215.333V181.333C25.4169 175.077 19.7362 170 12.7087 170C5.68062 170 0 175.077 0 181.333V215.333C0 246.579 28.505 272 63.5419 272H241.459C276.495 272 305 246.579 305 215.333V181.333C305 175.077 299.319 170 292.292 170Z" fill="#6E8192"/>
                           </svg>
                           `,
                              pan: false,
                           },
                        },
                     },
                     stroke: {
                        width: [0, 4],
                     },
                     title: {
                        text: 'Documents and Nr. Words',
                     },
                     dataLabels: {
                        enabled: true,
                        enabledOnSeries: [1],
                     },

                     xaxis: {
                        categories: [
                           'January',
                           'February',
                           'March',
                           'April',
                           'May',
                           'June',
                           'July',
                           'August',
                           'September',
                           'October',
                           'November',
                           'December',
                        ],
                     },
                     yaxis: [
                        {
                           axisTicks: {
                              show: true,
                           },
                           axisBorder: {
                              show: true,
                              color: '#008FFB',
                           },
                           labels: {
                              style: {
                                 colors: '#008FFB',
                              },
                           },
                           title: {
                              text: 'Nr of Words',
                              style: {
                                 color: '#008FFB',
                              },
                           },
                           tooltip: {
                              enabled: true,
                           },
                        },
                        {
                           axisTicks: {
                              show: true,
                           },
                           axisBorder: {
                              show: true,
                              color: '#00E396',
                           },
                           labels: {
                              style: {
                                 colors: '#00E396',
                              },
                           },
                           title: {
                              text: 'Documents',
                              style: {
                                 color: '#00E396',
                              },
                           },
                        },
                        {
                           opposite: true,
                           axisTicks: {
                              show: true,
                           },
                           axisBorder: {
                              show: true,
                              color: '#FEB019',
                           },
                           labels: {
                              style: {
                                 colors: '#FEB019',
                              },
                           },
                           title: {
                              text: 'Documents Deleted',
                              style: {
                                 color: '#FEB019',
                              },
                           },
                        },
                     ],
                  };
               }
            });
      } else {
         this.submissionService
            .getSubmissionGraphIndividual(this.year)
            .pipe(first())
            .subscribe(
               (data) => {
                  this.chartOptions = {
                     series: [
                        {
                           name: 'Nr of Words',
                           type: 'column',
                           data: data.documentsWords,
                        },
                        {
                           name: 'Documents',
                           type: 'line',
                           data: data.documentsSubmitted,
                        },
                        {
                           name: 'Re Submissions',
                           type: 'line',
                           data: data.documentsReSubmitted,
                        },
                     ],
                     chart: {
                        height: 400,
                        type: 'line',
                        toolbar: {
                           offsetX: 10,
                           offsetY: 2,
                           tools: {
                              reset: `<svg width="20" height="20" viewBox="0 0 315 315" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0)">
                        <path d="M307.475 141.686C300.582 137.088 291.268 138.95 286.673 145.843L286.227 146.512C280.801 72.405 218.777 13.767 143.309 13.767C64.288 13.768 0 78.056 0 157.077C0 236.098 64.288 300.386 143.309 300.386C154.355 300.386 163.309 291.432 163.309 280.386C163.309 269.34 154.355 260.386 143.309 260.386C86.344 260.385 40 214.041 40 157.077C40 100.113 86.344 53.768 143.309 53.768C196.107 53.768 239.774 93.583 245.88 144.764C241.067 138.773 232.394 137.342 225.88 141.686C218.987 146.282 217.126 155.596 221.722 162.488L251.424 207.029C254.929 212.285 260.632 215.423 266.679 215.423C272.726 215.423 278.428 212.284 281.931 207.029L311.633 162.488C316.228 155.595 314.367 146.282 307.475 141.686Z" fill="#6E8192"/>
                        </g>
                        <defs>
                        <clipPath id="clip0">
                        <rect width="314.154" height="314.154" fill="white"/>
                        </clipPath>
                        </defs>
                        </svg>
                        
                           `,
                              download: `<svg width="20" height="20" viewBox="0 0 305 272" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path d="M201.999 130.934C199.838 127.092 195.441 124.667 190.625 124.667H165.208V11.3332C165.208 5.07716 159.528 0 152.5 0C145.472 0 139.792 5.07716 139.792 11.3332V124.666H114.375C109.559 124.666 105.161 127.091 103.001 130.933C100.866 134.775 101.324 139.365 104.209 142.799L142.334 188.133C142.499 188.325 142.753 188.393 142.931 188.575C143.77 189.459 144.761 190.139 145.867 190.762C146.324 191.011 146.68 191.34 147.176 191.544C148.802 192.224 150.581 192.666 152.501 192.666C154.42 192.666 156.199 192.224 157.838 191.544C158.321 191.34 158.689 191.011 159.134 190.762C160.24 190.139 161.231 189.459 162.07 188.575C162.235 188.393 162.502 188.325 162.667 188.133L200.792 142.799C203.689 139.366 204.147 134.776 201.999 130.934Z" fill="#6E8192"/>
                           <path d="M292.292 170C285.264 170 279.584 175.077 279.584 181.333V215.333C279.584 234.078 262.478 249.333 241.459 249.333H63.5419C42.5225 249.333 25.4169 234.078 25.4169 215.333V181.333C25.4169 175.077 19.7362 170 12.7087 170C5.68062 170 0 175.077 0 181.333V215.333C0 246.579 28.505 272 63.5419 272H241.459C276.495 272 305 246.579 305 215.333V181.333C305 175.077 299.319 170 292.292 170Z" fill="#6E8192"/>
                           </svg>
                           `,
                              pan: false,
                           },
                        },
                     },
                     stroke: {
                        width: [0, 4],
                     },
                     title: {
                        text: 'Documents and Nr. Words',
                     },
                     dataLabels: {
                        enabled: true,
                        enabledOnSeries: [1],
                     },

                     xaxis: {
                        categories: [
                           'January',
                           'February',
                           'March',
                           'April',
                           'May',
                           'June',
                           'July',
                           'August',
                           'September',
                           'October',
                           'November',
                           'December',
                        ],
                     },
                     yaxis: [
                        {
                           axisTicks: {
                              show: true,
                           },
                           axisBorder: {
                              show: true,
                              color: '#008FFB',
                           },
                           labels: {
                              style: {
                                 colors: '#008FFB',
                              },
                           },
                           title: {
                              text: 'Nr of Words',
                              style: {
                                 color: '#008FFB',
                              },
                           },
                           tooltip: {
                              enabled: true,
                           },
                        },
                        {
                           axisTicks: {
                              show: true,
                           },
                           axisBorder: {
                              show: true,
                              color: '#00E396',
                           },
                           labels: {
                              style: {
                                 colors: '#00E396',
                              },
                           },
                           title: {
                              text: 'Documents',
                              style: {
                                 color: '#00E396',
                              },
                           },
                        },
                        {
                           opposite: true,
                           axisTicks: {
                              show: true,
                           },
                           axisBorder: {
                              show: true,
                              color: '#FEB019',
                           },
                           labels: {
                              style: {
                                 colors: '#FEB019',
                              },
                           },
                           title: {
                              text: 'Documents Deleted',
                              style: {
                                 color: '#FEB019',
                              },
                           },
                        },
                     ],
                  };
               },
               (error) => {
                  console.log('error', error);
               }
            );
      }
      this.currentUser = this.authService.getCurrentUser();
      if (this.user.institutionId === null) {
         this.statisticsService
            .transactions(this.currentUser.id)
            .pipe(first())
            .subscribe(
               (data) => {
                  this.transactions = data;
                  this.creditsChartOptions = {
                     series: [
                        {
                           name: 'Spend Credits',
                           data: this.transactions.spend,
                           color: '#f26f87',
                        },
                        {
                           name: 'Purchased Credits',
                           data: this.transactions.bought,
                           color: '#3960D1',
                        },
                     ],
                     chart: {
                        height: 350,
                        type: 'area',
                     },
                     dataLabels: {
                        enabled: true,
                     },
                     stroke: {
                        curve: 'smooth',
                     },
                     xaxis: {
                        categories: [
                           'January',
                           'February',
                           'March',
                           'April',
                           'May',
                           'June',
                           'July',
                           'August',
                           'September',
                           'October',
                           'November',
                           'December',
                        ],
                        labels: {
                           rotateAlways: false,
                           style: {
                              colors: '#ABAFB3',
                              fontSize: '8px',
                              fontWeight: 'bold',
                           },
                        },
                     },
                     tooltip: {
                        x: {
                           format: 'dd/MM/yy HH:mm',
                        },
                     },
                  };
               },
               (error) => {
                  console.log('error', error);
               }
            );

         this.statisticsService
            .transactionsPagination(
               this.currentUser.id,
               this.pageTransaction,
               0,
               this.pageSize
            )
            .pipe(first())
            .subscribe((data) => {
               this.transactionsPagination = data;
            });
      }
   }

   downloadExcel() {
      this.excelService
         .transactions(this.currentUser.id)
         .pipe(first())
         .subscribe(
            (data) => {
               saveAs(data.data, 'Transactions.pdf');
            },
            (error) => {
               console.log(error);
            }
         );
   }
   /**
    *  Method is called when a directive, pipe, or service is destroyed. Use for any custom cleanup that needs to occur when the instance is destroyed. In our case we will use to unsubscribe.
    */
   ngOnDestroy() {
      this.user$.unsubscribe();
      this.submissionsDetailsSub.unsubscribe();
      if (this.user.institutionId === null) {
         this.submissionsGraphSub.unsubscribe();
      }
   }

   /**
    * Method used to change pagination page. It dispatches an action with page and title as params.
    * @param event
    */
   changePage(event) {
      this.page = event;
      this.store.dispatch(
         submissionsActions.loadSubmissions({
            page: event,
            title: this.title,
            filter: this.filter,
            pageSize: this.pageSize,
         })
      );
   }
   /**
    * Method used to change transactions pagination page.
    * @param event
    */
   changeTransactionPage(event) {
      this.spinner.show();
      this.pageTransaction = event;
      this.statisticsService
         .transactionsPagination(
            this.currentUser.id,
            this.pageTransaction,
            0,
            this.pageSize
         )
         .pipe(first())
         .subscribe(
            (data) => {
               this.spinner.hide();
               this.transactionsPagination = data;
            },
            (error) => {
               this.spinner.hide();
               console.log('error', error);
            }
         );
   }

   /**
    * Method used filter submissions by title
    */
   titleFilter() {
      let time;
      time = 800;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
         this.page = 1;
         this.store.dispatch(
            submissionsActions.loadSubmissions({
               page: this.page,
               title: this.title,
               filter: this.filter,
               pageSize: this.pageSize,
            })
         );
      }, time);
   }
   /**
    * Method used to get submissions by specific filter (submission plagiarism percentage )
    * @param filter
    */
   changeValue(filter, reSubmission) {
      if (reSubmission === undefined) {
         reSubmission = 0;
         this.reSubmission = false;
      } else {
         this.reSubmission = true;
      }
      this.isFilterActive = true;
      this.filter = filter;
      this.page = 1;
      this.store.dispatch(
         submissionsActions.loadSubmissions({
            page: this.page,
            title: this.title,
            filter: this.filter,
            pageSize: this.pageSize,
            reSubmissions: reSubmission,
         })
      );
   }
   /**
    * Method used to change the year of documents & nr. words graph, to get data by year
    * @param event
    */
   onChange(event) {
      this.year = event;
      this.store.dispatch(
         submissionsActions.loadSubmissionsGraph({ year: this.year })
      );
   }
   /**
    * Method used to open report in a new tab.
    * @param url
    */
   goToLink(id: string) {
      let url = `${environment.currentUrl}/individual/submission/${id}/report`;
      window.open(url, '_blank');
   }
   /**
    * Method used to show toaster warning
    * @param message
    */
   showWarning(message) {
      this.toastr.warning(message);
   }

   /**
    * Method used to alert user before deleting a submission.
    * @param id submission Id.
    */
   async deleteSubmissionAlert(id, showSubmission): Promise<void> {
      const result = await swal.fire({
         title: 'Delete this document?',
         text: 'You will not be able to recover it',
         icon: 'warning',
         confirmButtonText: 'Yes, delete it',
         confirmButtonColor: '#f34135',
         showCancelButton: true,
         focusCancel: true,
      });
      if (result.isConfirmed) {
         this.deleteSubmission(id, showSubmission);
      }
   }
   /**
    * Method used to delete a submission
    * @param id submission id
    */
   deleteSubmission(id, showSubmission) {
      this.store.dispatch(
         submissionsActions.deleteSubmission({
            id: id,
            showSubmission: !showSubmission,
         })
      );
      swal.fire('Deleted!', 'Document deleted successfully', 'success');
   }
   /**
    * Method used to alert user the reason why can't upload the document.
    */
   async showAlertMessage() {
      let title = 'Not enough credits!';
      let message =
         "Your document is suspended because you don't have enough credits to process it!";
      const result = await swal.fire({
         title: title,
         text: message,
         icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#b5adad',
         confirmButtonText: 'Buy more credits',
         allowOutsideClick: false,
      });
      if (result.isConfirmed) {
         this.router.navigateByUrl('/individual/add-credits');
      } else if (result.isDismissed) {
         this.router.navigateByUrl('/individual/dashboard');
      }
   }
}
