import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
   selector: 'app-upload',
   templateUrl: './upload.component.html',
   styleUrls: ['./upload.component.scss'],
})
export class UploadComponent implements OnInit {
   constructor(private router: Router, public route: ActivatedRoute) {}

   ngOnInit(): void {}
}
