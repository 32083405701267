import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InviteStudentComponent } from '../modals/invite-student/invite-student.component';
import { EditUserComponent } from '../modals/edit-user/edit-user.component';
import { Store } from '@ngrx/store';
import * as individualAdministratorActions from '../state/action/individualAdministrator.actions';
import { getIndividualAdministratorStudentsListStateDetails } from '../state/selector/individualAdministrator.selectors';
@Component({
   selector: 'app-students-list',
   templateUrl: './students-list.component.html',
   styleUrls: ['./students-list.component.scss'],
})
export class StudentsListComponent implements OnInit, OnDestroy {
   constructor(public dialog: MatDialog, private store: Store) {}
   ngOnDestroy(): void {
      this.users$.unsubscribe();
   }
   users;
   users$;
   page: number = 1;
   languages = [
      {
         text: 'English',
         value: 'en',
      },
      {
         text: 'Albanian',
         value: 'sq',
      },
      {
         text: 'German',
         value: 'de',
      },
      {
         text: 'Italian',
         value: 'it',
      },
      {
         text: 'French',
         value: 'fr',
      },
      {
         text: 'Spanish',
         value: 'es',
      },
      {
         text: 'Greek',
         value: 'el',
      },
      {
         text: 'Czech',
         value: 'cs',
      },
      {
         text: 'Turkish',
         value: 'tr',
      },
      {
         text: 'Slovak',
         value: 'sk',
      },
      {
         text: 'Lithuanian',
         value: 'lt',
      },
      {
         text: 'Latvian',
         value: 'lv',
      },
      {
         text: 'Polish',
         value: 'pl',
      },
      {
         text: 'Serbian',
         value: 'sr',
      },
      {
         text: 'Macedonian',
         value: 'mk',
      },
      {
         text: 'Portuguese',
         value: 'pt',
      },
      {
         text: 'Dutch',
         value: 'nl',
      },
      {
         text: 'Russian',
         value: 'ru',
      },
      {
         text: 'Bulgarian',
         value: 'bg',
      },
      {
         text: 'Hungarian',
         value: 'hu',
      },
      {
         text: 'Romanian',
         value: 'ro',
      },
      {
         text: 'Slovenian',
         value: 'sl',
      },
      {
         text: 'Swedish',
         value: 'sv',
      },
      {
         text: 'Finnish',
         value: 'fi',
      },
      {
         text: 'Croatian',
         value: 'hr',
      },
      {
         text: 'Bosnian',
         value: 'bs',
      },
   ];
   ngOnInit(): void {
      this.store.dispatch(
         individualAdministratorActions.loadStudentsLists({ page: this.page })
      );

      this.users$ = this.store
         .select(getIndividualAdministratorStudentsListStateDetails)
         .subscribe((data) => {
            if (data !== null) {
               this.users = data;

               if (this.users.students !== null) {
                  for (let user of this.users.users) {
                     for (let language of this.languages) {
                        if (
                           user.allowedLanguages.documentLanguage ===
                           language.value
                        ) {
                           user.allowedLanguages.documentLanguage =
                              language.text;
                        }
                        if (
                           user.allowedLanguages.targetLanguage ===
                           language.value
                        ) {
                           user.allowedLanguages.targetLanguage = language.text;
                        }
                     }
                  }
               }
            }
         });
   }

   /**
    * Method used to change pagination page. It dispatches an action with page and title as params.
    * @param page
    */
   changePage(page): void {
      this.store.dispatch(
         individualAdministratorActions.loadStudentsLists({ page })
      );

      this.page = page;
   }

   openInviteModal() {
      this.dialog.open(InviteStudentComponent, {
         width: '70%',
      });
   }

   openEditModal(id) {
      let selectedUser;
      for (let user of this.users.users) {
         if (user.id === id) {
            selectedUser = user;
         }
      }

      this.dialog.open(EditUserComponent, {
         data: {
            user: selectedUser,
            page: this.page,
         },
         width: '50%',
      });
   }
}
