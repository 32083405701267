<div
   *ngIf="!this.hasRoute('store/')"
   style="display: flex; width: 100%; padding-top: 2%"
>
   <!-- <ng-container *ngFor="let license of this.licenses">
      <div style="width: 50%">
         <div style="display: flex; justify-content: center">
            <div style="width: 85%">
               <div style="display: flex; justify-content: space-between">
                  <span> Validity </span>
                  <span> {{ license.validity }} days left </span>
               </div>

               <div style="display: flex; justify-content: space-between">
                  <span> Professors Accounts </span>
                  <span>
                     {{ license.professors }}/{{
                        license.Institution.professors
                     }}</span
                  >
               </div>
               <div style="display: flex; justify-content: space-between">
                  <span> Teaching Assistants Accounts </span>
                  <span>
                     {{ license.teachingAssistants || 0 }}/{{
                        license.Institution.teachingAssistants
                     }}</span
                  >
               </div>
               <div style="display: flex; justify-content: space-between">
                  <span> Academic Integrity Officers Accounts </span>
                  <span>
                     {{ license.academicIntegrityOfficers }}/{{
                        license.Institution.academicIntegrityOfficers
                     }}</span
                  >
               </div>

               <div style="display: flex; justify-content: space-between">
                  <span> Student Accounts </span>
                  <span>
                     {{ license.students }}/{{
                        license.Institution.students
                     }}</span
                  >
               </div>

               <div style="display: flex; justify-content: space-between">
                  <span> Faculties </span>
                  <span> Unlimited </span>
               </div>
               <div style="display: flex; justify-content: space-between">
                  <span> Departments </span>
                  <span> Unlimited </span>
               </div>
               <div style="display: flex; justify-content: space-between">
                  <span> Subjects </span>
                  <span> Unlimited </span>
               </div>
               <div style="display: flex; justify-content: space-between">
                  <span> Assignments </span>
                  <span> Unlimited </span>
               </div>
               <div style="display: flex; justify-content: space-between">
                  <span> Thesis </span>
                  <span>
                     {{ license.thesis }}/{{
                        license.Institution.bachelor +
                           license.Institution.master +
                           license.Institution.phd
                     }}
                  </span>
               </div>
               <div style="display: flex; justify-content: space-between">
                  <span> Repositories </span>
                  <span> See list </span>
               </div>
            </div>
         </div>
         <div style="display: flex; justify-content: space-around">
            <span routerLink="manage"> Manage </span>
            <span routerLink="offers"> See offers </span>
         </div>
      </div>
   </ng-container> -->

   <ng-container>
      <section>
         <section>
            <div>
               <h3>Your Plan</h3>
            </div>
         </section>
         <section>
            <div>
               <div>
                  <div style="display: flex; justify-content: space-between">
                     <span> Users </span>
                     <span routerLink="manage/users"> Manage </span>
                  </div>
                  <!-- <section>
                     <div style="display: grid; padding-left: 5%; width: 100%">
                        <span>
                           Administrators
                           <b>
                              {{ this.licenses[0]?.administrators }}
                           </b>
                        </span>
                        <span>
                           Academic Integrity Officers
                           <b>{{
                              this.licenses[0]?.academicIntegrityOfficers
                           }}</b>
                        </span>
                        <span>
                           Professors
                           <b>
                              {{ this.licenses[0]?.professors }}
                           </b>
                        </span>
                        <span>
                           Teaching Assistants
                           <b>
                              {{ this.licenses[0]?.teachingAssistants }}
                           </b>
                        </span>
                        <span>
                           Students
                           <b>
                              {{ this.licenses[0]?.students }}
                           </b>
                        </span>
                     </div>
                  </section> -->
                  <span>
                     {{
                        this.licenses[0]?.administrators +
                           this.licenses[0]?.professors +
                           this.licenses[0]?.teachingAssistants +
                           this.licenses[0]?.academicIntegrityOfficers +
                           this.licenses[0]?.students
                     }}
                  </span>
               </div>
            </div>
         </section>
         <hr />
         <section>
            <div>
               <div style="display: flex; justify-content: space-between">
                  <span>
                     <b>Addons</b>
                  </span>
                  <span routerLink="manage/addons"> Manage </span>
               </div>
               <div style="display: grid; padding-left: 5%; width: 100%">
                  <span>
                     Bachelor theses {{ this.licenses[0]?.bachelor }}
                  </span>
                  <span> Master theses {{ this.licenses[0]?.master }} </span>
                  <span> Ph.d. theses {{ this.licenses[0]?.phd }} </span>
                  <section>
                     <span>Crosslingual feature</span>
                     <div style="display: grid; padding-left: 5%; width: 100%">
                        <span>
                           Assignments
                           <b>
                              {{
                                 this.licenses[0]?.crossLingual.assignments ===
                                 true
                                    ? 'Yes'
                                    : 'No'
                              }}
                           </b>
                        </span>
                        <span>
                           professorUploads
                           <b>
                              {{
                                 this.licenses[0]?.crossLingual
                                    .professorUploads === true
                                    ? 'Yes'
                                    : 'No'
                              }}
                           </b>
                        </span>
                        <span>
                           thesis
                           <b>
                              {{
                                 this.licenses[0]?.crossLingual.thesis === true
                                    ? 'Yes'
                                    : 'No'
                              }}
                           </b>
                        </span>
                     </div>
                  </section>
                  <section>
                     <span
                        >Professor individual uploads
                        <b>{{
                           this.licenses[0]?.professorIndividualUploads
                        }}</b></span
                     >
                  </section>
               </div>
            </div>
         </section>
         <hr />
         <section>
            <div style="display: flex; justify-content: space-between">
               <span>
                  Support <b>{{ this.licenses[0]?.support }}</b>
               </span>
               <span> Manage </span>
            </div>
         </section>
         <hr />
         <section>
            <div style="display: flex; justify-content: space-between">
               <span>
                  Commitment <b>{{ this.licenses[0]?.commitment }}</b>
               </span>
               <span routerLink="manage/commitment"> Manage </span>
            </div>
         </section>
         <hr />
         <section>
            <div style="display: flex; justify-content: space-between">
               <span>
                  Billing Plan <b>{{ this.licenses[0]?.billingPlan }}</b>
               </span>
               <span> Manage </span>
            </div>
         </section>
      </section>
   </ng-container>
</div>

<router-outlet></router-outlet>
