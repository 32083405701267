<div class="submissions">
   <div routerLink="assignment-submissions" routerLinkActive="active">
      <span>Assignment</span>
   </div>
   <div routerLink="professors-submissions" routerLinkActive="active">
      <span>Professors</span>
   </div>
   <div routerLink="thesis" routerLinkActive="active"><span>Theses</span></div>
</div>
<router-outlet></router-outlet>
