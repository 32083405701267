// *** Angular
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as departmentsActions from '../../state/action/units-statistics-departments.actions';
import { getDepartmentsStatisticsDetails } from '../../state/selector/units-statistics-departments.selectors';
import * as facultyActions from '../../../../../state/faculty/action/faculty.actions';
import { getInstitutionFacultiesStateDetails } from '../../../../../state/faculty/selector/faculty.selectors';
import { getFiltersStateDetails } from '../../../../../../administrator-components/state/filters/selector/filters.selectors';
import * as filtersActions from '../../../../../../administrator-components/state/filters/action/filters.actions';
import { Router } from '@angular/router';

@Component({
   selector: 'app-departments-statistics',
   templateUrl: './departments-statistics.component.html',
   styleUrls: ['./departments-statistics.component.scss'],
})
export class DepartmentsStatisticsComponent implements OnInit, OnDestroy {
   /**
    * Variable is used to store facultyId to filter submissions of assignments within specific faculty.
    */
   faculty = '-1';
   /**
    * Variable used to store information that a thesis is been clicked for expand or not
    */
   expandInfo: any;
   /**
    * Variable is used to store departments of selected faculty
    */
   departments;
   /**
    * Variable is used to store facultyId to filter submissions of assignments within specific faculty.
    */
   departmentId = -1;
   /**
    * Symbolizing the page of pagination
    */
   page: number = 1;
   /**
    * Representing tile model
    */
   departmentName: string;
   /**
    * Timer used for search delay
    */
   timer: any;
   /**
    * Variabel used to store total number of departments without pagination
    */
   totalDepartments;
   /**
    * Variable is used to store institution faculties
    */
   faculties;
   getFiltersStateDetails$: any;
   getInstitutionFacultiesStateDetails$: any;
   getDepartmentsStatisticsDetails$: any;
   constructor(private store: Store, private router: Router) {}
   ngOnDestroy(): void {
      this.getInstitutionFacultiesStateDetails$.unsubscribe();
      this.getDepartmentsStatisticsDetails$.unsubscribe();
      this.getFiltersStateDetails$.unsubscribe();
   }

   ngOnInit(): void {
      this.getFiltersStateDetails$ = this.store
         .select(getFiltersStateDetails)
         .subscribe((data) => {
            if (data.departmentsFilters.faculty != null) {
               this.faculty = data.departmentsFilters.faculty;
            }
         });

      this.store.dispatch(facultyActions.loadFacultiesDetails({ page: 0 }));

      this.store.dispatch(
         departmentsActions.loadUnitsStatisticsDepartments({
            page: this.page,
            title: this.departmentName,
            faculty: this.faculty == '-1' ? undefined : this.faculty,
         })
      );

      this.getInstitutionFacultiesStateDetails$ = this.store
         .select(getInstitutionFacultiesStateDetails)
         .subscribe((data) => {
            if (data !== null && this.faculties == undefined) {
               this.faculties = data.faculties;
            }
         });

      this.getDepartmentsStatisticsDetails$ = this.store
         .select(getDepartmentsStatisticsDetails)
         .subscribe((data) => {
            if (data !== null) {
               this.departments = data.departmentsStatistics;
               this.totalDepartments = data.departmentsNumber;
            }
         });
   }
   /**
    * Function used to filter departments by title
    */
   titleFilter() {
      let time;
      time = 500;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
         this.store.dispatch(
            departmentsActions.loadUnitsStatisticsDepartments({
               page: this.page,
               title: this.departmentName,
               faculty: this.faculty == '-1' ? undefined : this.faculty,
            })
         );
      }, time);
   }
   /**
    * Function used to filter departments by faculty
    */
   selectedFaculty() {
      this.store.dispatch(
         filtersActions.departmentsFilters({
            faculty: this.faculty,
         })
      );
      this.store.dispatch(
         departmentsActions.loadUnitsStatisticsDepartments({
            page: this.page,
            title: this.departmentName,
            faculty: this.faculty == '-1' ? undefined : this.faculty,
         })
      );
   }

   /**
    * Method used to change pagination page. It dispatches an action with page as param.
    * @param event
    */
   changePage(event): void {
      this.page = event;
      this.store.dispatch(
         departmentsActions.loadUnitsStatisticsDepartments({
            page: this.page,
            title: this.departmentName,
            faculty: this.faculty == '-1' ? undefined : this.faculty,
         })
      );
   }
   /**
    * Function is used to exapnd information
    * @param departmentId
    */
   expand(departmentId) {
      this.departmentId = departmentId;
      if (this.expandInfo !== departmentId) {
         this.expandInfo = departmentId;
      } else {
         this.expandInfo = -1;
      }
   }
   /**
    * Function is used when clicked on number of subjects of department to redirect to subjects with filter activated to clicked department.
    * @param departmentId
    * @param facultyId
    */
   subjectsFilter(departmentId, facultyId) {
      this.store.dispatch(
         filtersActions.subjectsFilters({
            department: departmentId,
            faculty: facultyId,
         })
      );
      this.router.navigateByUrl(
         '/administrator/statistics/academic-units/subjects'
      );
   }
   /**
    * Function is used when clicked on number of assignments of department to redirect to assignments with filter activated to clicked department.
    * @param departmentId
    * @param facultyId
    */
   assignmentFilter(departmentId, facultyId) {
      this.store.dispatch(
         filtersActions.assignmentFilters({
            department: departmentId,
            faculty: facultyId,
         })
      );
      this.router.navigateByUrl(
         '/administrator/statistics/academic-units/assignments'
      );
   }
   /**
    * Function is used when clicked on number of professors of department to redirect to professors with filter activated to clicked department.
    * @param departmentId
    * @param facultyId
    */
   professorFilter(departmentId, facultyId) {
      this.store.dispatch(
         filtersActions.professorsFilters({
            department: departmentId,
            faculty: facultyId,
         })
      );
      this.router.navigateByUrl(
         '/administrator/statistics/users/professors'
      );
   }
   /**
    * Function is used when clicked on number of students of department to redirect to students with filter activated to clicked department.
    * @param departmentId
    * @param facultyId
    */
   studentsFilter(departmentId, facultyId) {
      this.store.dispatch(
         filtersActions.studentsFilters({
            department: departmentId,
            faculty: facultyId,
         })
      );
      this.router.navigateByUrl('/administrator/statistics/users/students');
   }
   /**
    * Function is used when clicked on number of thesis of department to redirect to thesis with filter activated to clicked department.
    * @param departmentId
    * @param facultyId
    */
   thesisFilter(departmentId, facultyId) {
      this.store.dispatch(
         filtersActions.thesisSubmissionsFilters({
            department: departmentId,
            faculty: facultyId,
         })
      );
      this.router.navigateByUrl('/administrator/statistics/submissions/thesis');
   }
   /**
    * Function is used when clicked on number of assignments submissions of department to redirect to assignments submissions with filter activated to clicked department.
    * @param departmentId
    * @param facultyId
    */
   assignmentsSubmissionsFilter(departmentId, facultyId) {
      this.store.dispatch(
         filtersActions.assignmentSubmissionsFilters({
            department: departmentId,
            faculty: facultyId,
         })
      );
      this.router.navigateByUrl(
         '/administrator/statistics/submissions/assignment-submissions'
      );
   }
   /**
    * Function is used when clicked on number of professors submissions of department to redirect to professors submissions with filter activated to clicked department.
    * @param departmentId
    * @param facultyId
    */
   professorsSubmissionsFilter(departmentId, facultyId) {
      this.store.dispatch(
         filtersActions.professorSubmissionsFilters({
            department: departmentId,
            faculty: facultyId,
         })
      );
      this.router.navigateByUrl(
         '/administrator/statistics/submissions/professors-submissions'
      );
   }
   /**
    * Function is used to reset filters
    */
   resetFilter() {
      this.departmentName = undefined;
      this.faculty = '-1';
      this.page = 1;
      this.store.dispatch(
         filtersActions.departmentsFilters({
            faculty: this.faculty,
         })
      );
      this.store.dispatch(
         departmentsActions.loadUnitsStatisticsDepartments({
            page: this.page,
            title: this.departmentName,
            faculty: this.faculty == '-1' ? undefined : this.faculty,
         })
      );
   }
}
