<section class="flex statistics-adm">
   <main style="width: 100%">
      <div class="overview overview1 white border shadow">
         <h2>Institution overview</h2>
         <div>
            <div>
            <img src="../../../../assets/images/newIcons/18.Faculty.png" alt="">
               <div>
                  Faculties<span>{{
                     this.institutionData?.nrOfFaculties || 0
                  }}</span>
               </div>
            </div>
            <div>
               <img src="../../../../assets/images/newIcons/19.Department_Color.png" alt="">
               <div>
                  Departments<span>{{
                     this.institutionData?.nrOfDepartments || 0
                  }}</span>
               </div>
            </div>
            <div>
               <img src="../../../../assets/images/newIcons/1.Subject_Color.png" alt="">
               <div>
                  Subjects<span>{{
                     this.institutionData?.nrOfSubjects || 0
                  }}</span>
               </div>
            </div>
            <div>
               <img src="../../../../assets/images/newIcons/20.Professor_Color.png" alt="">
               <div>
                  Professors<span>{{
                     this.institutionData?.nrOfProfessors || 0
                  }}</span>
               </div>
            </div>
            <div>
               <img src="../../../../assets/images/newIcons/22.Student_Color.png" alt="">
               <div>
                  Students
                  <span> {{ this.institutionData?.nrOfStudents || 0 }} </span>
               </div>
            </div>
            <div>
               <img src="../../../../assets/images/newIcons/21.ActiveUser.png" alt="">
               <div>
                  Active users
                  <span>
                     {{
                        this.institutionData?.nrOfActiveUsers || 0
                     }}
                  </span>
               </div>
            </div>
            <div>
               <img src="../../../../assets/images/newIcons/2.Assignments_Color.png" alt="">
               <div>
                  Assignments
                  <span>{{ this.institutionData?.nrOfAssignments || 0 }}</span>
               </div>
            </div>
            <div>
               <img src="../../../../assets/images/newIcons/23.StudentSubmission_Color.png" alt="">
               <div>
                Student submissions<span>{{
                     this.institutionData?.nrOfStudentSubmissions || 0
                  }}</span>
               </div>
            </div>
            <div>
               <img src="../../../../assets/images/newIcons/24.Profesor-submissons.png" alt="">
               <div>
                  Professor submissions<span
                     >{{
                        this.institutionData?.nrOfProfessorSubmissions || 0
                     }}</span
                  >
               </div>
            </div>
            <div>
               <img src="../../../../assets/images/newIcons/7.Thesis_Bachelor_Color.png" alt="">
               <div>
                  Bachelor theses<span>{{
                     this.institutionData?.nrOfBachelorThesis || 0
                  }}</span>
               </div>
            </div>
            <div>
               <img src="../../../../assets/images/newIcons/8.Thesis_Master_Color.png" alt="">
               <div>
                  Master theses<span>{{
                     this.institutionData?.nrOfMasterThesis || 0
                  }}</span>
               </div>
            </div>
            <div>
               <img src="../../../../assets/images/newIcons/9.Thesis_PHD_Color.png" alt="">
               <div>
                  Ph.D theses<span>{{
                     this.institutionData?.nrOfPhdThesis || 0
                  }}</span>
               </div>
            </div>
            <div>
               <img src="../../../../assets/images/newIcons/10.HighSimilarity.png" alt="">
               <div>
                  High similarity<span>{{
                     this.institutionData
                        ?.nrOfHighSimilaritySubmissions || 0
                  }}</span>
               </div>
            </div>
            <div>
               <img src="../../../../assets/images/newIcons/11.MediumSimilarity.png" alt="">
               <div>
                  Medium similarity<span>{{
                     this.institutionData
                        ?.nrOfMediumSimilaritySubmissions || 0
                  }}</span>
               </div>
            </div>
            <div>
               <img src="../../../../assets/images/newIcons/12.LowSimilarity.png" alt="">
               <div>
                  Low similarity<span>{{
                     this.institutionData
                        ?.nrOfLowSimilaritySubmissions || 0
                  }}</span>
               </div>
            </div>
         </div>
      </div>
      <div class="overview white border shadow">
         <h2>Institution ratios</h2>
         <div>
            <div>
               <img src="../../../../assets/images/newIcons/25.Student-Profesor-Ratio.png" alt="">
               <div>
                  Student-to-Professor
                  <span>{{ this.institutionData?.nrOfStudents || 0 }} : {{this.institutionData?.nrOfProfessors || 0}}</span>
               </div>
            </div>
            <div>
               <img src="../../../../assets/images/newIcons/26.Student-Subject.png" alt="">
               <div>
                  Student-to-Subject <span>{{
                     this.institutionData?.nrOfStudents || 0
                  }} : {{ this.institutionData?.nrOfSubjects || 0 }}</span>
               </div>
            </div>
            <div>
               <img src="../../../../assets/images/newIcons/27.Student-Submission-Ratio.png" alt="">
               <div>
                  Student-to-Submission<span
                     >{{
                        this.institutionData?.nrOfStudents || 0
                     }} : {{
                        this.institutionData?.nrOfStudentSubmissions || 0
                     }}</span
                  >
               </div>
            </div>
            <div>
               <img src="../../../../assets/images/newIcons/28.Profesor-Subject-Ratio.png" alt="">
               <div>
                  Professor-to-Subject<span
                  >{{
                     this.institutionData?.nrOfProfessors || 0
                  }} : {{
                     this.institutionData?.nrOfSubjects || 0
                  }}</span
               >
               </div>
            </div>
            <div>
               <img src="../../../../assets/images/newIcons/29.Profesor-Assignments-Ratio.png" alt="">
               <div>
                  Professor-to-Assignment<span
                  >{{
                     this.institutionData?.nrOfProfessors || 0
                  }} : {{
                     this.institutionData?.nrOfAssignments || 0
                  }}</span
               >
               </div>
            </div>
            <div>
               <img src="../../../../assets/images/newIcons/30.Professor-Submission-Ratio.png" alt="">
               <div>
                  Professor-to-Submission<span
                  >{{
                     this.institutionData?.nrOfProfessors || 0
                  }} : {{
                     this.institutionData?.nrOfProfessorSubmissions || 0
                  }}</span
               >
               </div>
            </div>
            <div>
               <img src="../../../../assets/images/newIcons/31.Bachelor-professors.png" alt="">
               <div>
                  Bachelors-to-Professor<span
                  >{{
                     this.institutionData?.nrOfBachelorThesis || 0
                  }} : {{
                     this.institutionData?.nrOfProfessors || 0
                  }}</span
               >
               </div>
            </div>
            <div>
               <img src="../../../../assets/images/newIcons/32.Master-professors.png" alt="">
               <div>
                  Masters-to-Professor<span
                  >{{
                     this.institutionData?.nrOfMasterThesis || 0
                  }} : {{
                     this.institutionData?.nrOfProfessors || 0
                  }}</span
               >
               </div>
            </div>
            <div>
               <img src="../../../../assets/images/newIcons/32.PhD-professors.png" alt="">
               <div>
                  Ph.D.-to-Professor<span
                  >{{
                     this.institutionData?.nrOfPhdThesis || 0
                  }} : {{
                     this.institutionData?.nrOfProfessors || 0
                  }}</span
               >
               </div>
            </div>
         </div>
      </div>
      <div class="white-bg border shadow">
         <div class="filtersContainer">
            <select name="" id="" (change)="this.filter($event.target.value)">
               <option value="1">Student submissions</option>
               <option value="2">Students</option>
               <option value="3">Theses</option>
               <option value="4">Professor submissions</option>
               <option value="5">Professors</option>
            </select>
         </div>

         <ng-container *ngIf="this.graphToDisplay === 1">
            <app-submissions-institutional></app-submissions-institutional>
         </ng-container>
         <ng-container *ngIf="this.graphToDisplay === 2">
            <app-users-institutional></app-users-institutional>
         </ng-container>
         <ng-container *ngIf="this.graphToDisplay === 3">
            <app-thesis-institutional></app-thesis-institutional>
         </ng-container>
         <ng-container *ngIf="this.graphToDisplay === 4">
            <app-submissions-basic></app-submissions-basic>
         </ng-container>
         <ng-container *ngIf="this.graphToDisplay === 5">
            <app-users-basic></app-users-basic>
         </ng-container>
      </div>
      <div class="active-users-statistics">
         <div class="white-bg shadow border active-users">
            <div class="flex headerCard">
               <img src="../../../../assets/images/newIcons/33.ProfessorWithCreatedAssigmnents.png" alt="">
               <h5 class="bold">Professors with most created assignments</h5>
            </div>
            <div class="content">
               <ng-container *ngFor="let user of this.assignmentsStatistics">
                  <div class="flex">
                     <i class="fas fa-file"></i>
                     <span>{{ user._count.Assignments }} Assignments </span>
                     <h6 class="shadow border">{{ user.name }}</h6>
                  </div>
               </ng-container>
            </div>
         </div>
         <div class="white-bg shadow border active-users">
            <div class="flex withFilters">
               <div class="flex headerCard">
                  <img src="../../../../assets/images/newIcons/34.UserWithMostUploadedSubmission.png" alt="">
                  <h5 class="bold">Users with most uploaded submissions</h5>
               </div>
               <select name="" id="" (change)="changeType($event.target.value)">
                  <option value="4" selected>Professor</option>
                  <option value="5">Student</option>
               </select>
            </div>
            <div class="content">
               <ng-container *ngFor="let user of this.submissionsStatistics">
                  <div class="flex">
                     <i class="fas fa-file"></i>
                     <span>{{ user._count.Submissions }} Submissions </span>
                     <h6 class="shadow border">{{ user.name }}</h6>
                  </div>
               </ng-container>
            </div>
         </div>
         <div class="white-bg shadow border active-users">
            <div class="flex withFilters">
               <div class="flex headerCard">
                  <img src="../../../../assets/images/newIcons/35.LatestThesisSubmited.png" alt="">
                  <h5 class="bold">Recent theses submissions</h5>
               </div>
               <select
                  name=""
                  id=""
                  (change)="changeThesisType($event.target.value)"
               >
                  <option value="bachelor" selected>Bachelor</option>
                  <option value="master">Master</option>
                  <option value="phd">Ph.D.</option>
               </select>
            </div>
            <div class="content" >
               <ng-container *ngFor="let submission of thesisStatistics">
                  <div class="flex">
                     <i style="color: #70c598" class="far fa-file-alt"></i>
                     <span>{{ submission.thesis.name }} submissions</span>
                     <h6 class="shadow border">{{ submission.user.name }}</h6>
                  </div>
               </ng-container>
            </div>
         </div>
      </div>
   </main>
</section>
