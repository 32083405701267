// *** Angular
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';

// *** Actions
import * as assignmentsActions from '../state/action/units-statistics-assignments.actions';
import * as facultyActions from '../../../../state/faculty/action/faculty.actions';
import * as professorActions from '../../../../../administrator-components/state/professor/action/professor.actions';
import * as filtersActions from '../../../../../administrator-components/state/filters/action/filters.actions';

// *** Selectors
import { getAssignmentsStatisticsDetails } from '../../assignments/state/selector/units-statistics-assignments.selectors';
import { getInstitutionFacultiesStateDetails } from '../../../../state/faculty/selector/faculty.selectors';
import { getFiltersStateDetails } from '../../../../../administrator-components/state/filters/selector/filters.selectors';
import { getInstitutionProfessorsStateDetails } from '../../../../../administrator-components/state/professor/selector/professor.selectors';

// *** Services
import { DepartmentService } from 'src/app/services/department.service';

@Component({
   selector: 'app-assignments-statistics',
   templateUrl: './assignments-statistics.component.html',
   styleUrls: ['./assignments-statistics.component.scss'],
})
export class AssignmentsStatisticsComponent implements OnInit, OnDestroy {
   /**
    * Variable is used to store departments of selected faculty
    */
   departments: any;
   /**
    * Variable is used to store facultyId to filter submissions of assignments within specific faculty.
    */
   facultyId = '-1';
   /**
    * Variable is used to store departmentId to filter submissions of assignments within specific department.
    */
   departmentId = '-1';
   /**
    * Variabel used to store subject name that used to filter assignments by subject
    */
   subjectName: string;
   /**
    * Variable used to store professors of institution
    */
   professors;
   /**
    * Variabel used to filter assignments by specicif professor
    */
   professor = '-1';
   getFiltersState = true;

   /**
    * Variable used to store information that a thesis is been clicked for expand or not
    */
   expandInfo: any;
   /**
    * Variable used to store assignments within institutiono
    */
   assignments;
   /**
    * Variable used to store professor id that used to expand specific row on HTML
    */
   assignmentId;
   /**
    * Symbolizing the page of pagination
    */
   page: number = 1;
   /**
    * Representing tile model
    */
   assignmentName: string;
   /**
    * Timer used for search delay
    */
   timer: any;
   /**
    * Variabel used to store total assignemnts without pagination
    */
   totalAssignments;
   /**
    * Variabels used to retrieve faculties of insitution from store.
    */
   faculties;
   faculties$;
   getInstitutionProfessorsStateDetails$: any;
   getInstitutionFacultiesStateDetails$: any;
   getAssignmentsStatisticsDetails$: any;
   getFiltersStateDetails$: any;
   constructor(
      private store: Store,
      private departmentService: DepartmentService,
      private router: Router
   ) {}
   ngOnDestroy(): void {
      this.getInstitutionProfessorsStateDetails$.unsubscribe();
      this.getInstitutionFacultiesStateDetails$.unsubscribe();
      this.getAssignmentsStatisticsDetails$.unsubscribe();
      this.getFiltersStateDetails$.unsubscribe();
   }

   ngOnInit(): void {
      this.getFiltersStateDetails$ = this.store
         .select(getFiltersStateDetails)
         .subscribe((data) => {
            if (data.assignmentFilters.faculty != null) {
               this.facultyId = data.assignmentFilters.faculty;
               this.departmentService
                  .getDepartmentsOfFaculty(this.facultyId)
                  .pipe(first())
                  .subscribe(
                     (departmentsDetails) => {
                        this.departments = departmentsDetails.departments;
                        if (data.assignmentFilters.department != null) {
                           this.departmentId =
                              data.assignmentFilters.department;
                           this.store.dispatch(
                              assignmentsActions.loadUnitsStatisticsAssignments(
                                 {
                                    page: this.page,
                                    title: this.assignmentName,
                                    faculty:
                                       this.facultyId == '-1'
                                          ? undefined
                                          : this.facultyId,
                                    department:
                                       this.departmentId == '-1'
                                          ? undefined
                                          : this.departmentId,
                                    subject: this.subjectName,
                                    professor:
                                       this.professor == '-1'
                                          ? undefined
                                          : this.professor,
                                 }
                              )
                           );
                        }
                     },
                     (error) => {
                        console.log(error);
                     }
                  );
            }
            if (data.assignmentFilters.subject != null) {
               this.subjectName = data.assignmentFilters.subject;
            }
            if (data.assignmentFilters.professor != null) {
               this.professor = data.assignmentFilters.professor;
            }
         });

      this.store.dispatch(facultyActions.loadFacultiesDetails({ page: 0 }));

      this.store.dispatch(
         assignmentsActions.loadUnitsStatisticsAssignments({
            page: this.page,
            title: this.assignmentName,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            subject: this.subjectName,
            professor: this.professor == '-1' ? undefined : this.professor,
         })
      );

      this.store.dispatch(professorActions.loadProfessorsDetails({ page: 0 }));

      this.getInstitutionProfessorsStateDetails$ = this.store
         .select(getInstitutionProfessorsStateDetails)
         .subscribe((data) => {
            if (data.professors !== null && this.professors == undefined) {
               this.professors = data.professors;
            }
         });

      this.getInstitutionFacultiesStateDetails$ = this.store
         .select(getInstitutionFacultiesStateDetails)
         .subscribe((data) => {
            if (data.faculties !== null && this.faculties == undefined) {
               this.faculties = data.faculties;
            }
         });

      this.getAssignmentsStatisticsDetails$ = this.store
         .select(getAssignmentsStatisticsDetails)
         .subscribe((data) => {
            if (data !== null) {
               this.assignments = data.assignmentsStatistics;
               this.totalAssignments = data.assignmentsNumber;
            }
         });
   }

   /**
    * Function is used to filter by title
    */
   filterWithTimeout() {
      this.getFiltersState = false;
      this.store.dispatch(
         filtersActions.assignmentFilters({
            professor: this.professor,
            department: this.departmentId,
            faculty: this.facultyId,
            subject: this.subjectName,
         })
      );
      let time;
      time = 500;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
         this.page = 1;
         this.store.dispatch(
            assignmentsActions.loadUnitsStatisticsAssignments({
               page: this.page,
               title: this.assignmentName,
               faculty: this.facultyId == '-1' ? undefined : this.facultyId,
               department:
                  this.departmentId == '-1' ? undefined : this.departmentId,
               subject: this.subjectName,
               professor: this.professor == '-1' ? undefined : this.professor,
            })
         );
      }, time);
   }

   /**
    * Function is used to filter by selected faculty
    */
   selectedFaculty() {
      this.page = 1;
      this.getFiltersState = false;
      this.store.dispatch(
         filtersActions.assignmentFilters({
            professor: this.professor,
            department: this.departmentId,
            faculty: this.facultyId,
            subject: this.subjectName,
         })
      );
      this.store.dispatch(
         assignmentsActions.loadUnitsStatisticsAssignments({
            page: this.page,
            title: this.assignmentName,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            subject: this.subjectName,
            professor: this.professor == '-1' ? undefined : this.professor,
         })
      );
      if (this.facultyId !== '') {
         this.departmentService
            .getDepartmentsOfFaculty(this.facultyId)
            .pipe(first())
            .subscribe(
               (data) => {
                  this.departments = data.departments;
               },
               (error) => {
                  console.log(error);
               }
            );
      }
   }

   /**
    * Function is used to filter by selected department
    */
   selectedDepartment() {
      this.page = 1;
      this.getFiltersState = false;
      this.store.dispatch(
         filtersActions.assignmentFilters({
            professor: this.professor,
            department: this.departmentId,
            faculty: this.facultyId,
            subject: this.subjectName,
         })
      );
      this.store.dispatch(
         assignmentsActions.loadUnitsStatisticsAssignments({
            page: this.page,
            title: this.assignmentName,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            department:
               this.departmentId == '-1' ? undefined : this.departmentId,
            subject: this.subjectName,
            professor: this.professor == '-1' ? undefined : this.professor,
         })
      );
   }

   /**
    * Function is used to filter assignments by specific professor
    */
   filterByProfessor() {
      this.getFiltersState = false;
      this.store.dispatch(
         filtersActions.assignmentFilters({
            professor: this.professor,
            department: this.departmentId,
            faculty: this.facultyId,
            subject: this.subjectName,
         })
      );
      this.page = 1;
      this.store.dispatch(
         assignmentsActions.loadUnitsStatisticsAssignments({
            page: this.page,
            title: this.assignmentName,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            department:
               this.departmentId == '-1' ? undefined : this.departmentId,
            subject: this.subjectName,
            professor: this.professor == '-1' ? undefined : this.professor,
         })
      );
   }

   /**
    * Method used to change pagination page. It dispatches an action with page as param.
    * @param event
    */
   changePage(event): void {
      this.page = event;
      this.store.dispatch(
         assignmentsActions.loadUnitsStatisticsAssignments({
            page: this.page,
            title: this.assignmentName,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            department:
               this.departmentId == '-1' ? undefined : this.departmentId,
            subject: this.subjectName,
            professor: this.professor == '-1' ? undefined : this.professor,
         })
      );
   }

   /**
    * Function is used to exapnd information
    * @param assignmentId
    */
   expand(assignmentId) {
      this.assignmentId = assignmentId;
      if (this.expandInfo !== assignmentId) {
         this.expandInfo = assignmentId;
      } else {
         this.expandInfo = -1;
      }
   }

   /**
    * Function is used to reset filters
    */
   resetFilter() {
      this.facultyId = '-1';
      this.departmentId = '-1';
      this.professor = '-1';
      this.subjectName = undefined;
      this.assignmentName = undefined;
      this.page = 1;
      this.store.dispatch(
         filtersActions.assignmentFilters({
            professor: this.professor,
            department: this.departmentId,
            faculty: this.facultyId,
            subject: this.subjectName,
         })
      );
      this.store.dispatch(
         assignmentsActions.loadUnitsStatisticsAssignments({
            page: this.page,
            title: this.assignmentName,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            department:
               this.departmentId == '-1' ? undefined : this.departmentId,
            subject: this.subjectName,
            professor: this.professor == '-1' ? undefined : this.professor,
         })
      );
   }

   /**
    * Function used when clicked on submissions within assignment and redirects on submissions with filter activated with clicked assignment
    * @param assignmentName
    */
   filterAssignments(assignmentName) {
      this.store.dispatch(
         filtersActions.assignmentSubmissionsFilters({
            assignment: assignmentName,
         })
      );
      this.router.navigateByUrl(
         '/administrator/statistics/submissions/assignment-submissions'
      );
   }
}
