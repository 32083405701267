<div class="contact-us">
    <h4>Contact crossplag team for this offer</h4>

 <form action="" [formGroup]="contactUs">
    <div class="flex">
        <div>
            <h5>Full name</h5>
            <input disabled placeholder="name" formControlName="name" type="text">
        </div>
        <div>
            <h5>Email</h5>
            <input disabled placeholder="email" formControlName="email" type="text">
        </div>
    </div>
    <h5>Subject</h5>
    <input placeholder="subject" formControlName="subject" type="text">
    <h5>Message</h5>
    <textarea formControlName="message" placeholder="message" name="" id="" cols="30" rows="10">
    </textarea>
    <div
    class="offersTable"
 >
    <div class="header">
       <div>
          <span>Plan name</span>
          <h2>{{ data.offer.title }}</h2>
       </div>
       <a (click)="moreOfferDetails()">See more</a>
    </div>
    <div
       class="offerExpandedDetails"
       *ngIf="showOfferDetails"
    >
       <div>
          <h5>Number of Students:</h5>
          <b>{{ data.offer.students }}</b>
          <!-- <a (click)="changeEditInput('student')">Add user</a> -->
       </div>
       <div>
          <h5>Number of Professor:</h5>
          <b>{{ data.offer.professors }}</b>
          <!-- <a>Add user</a> -->
       </div>
       <div>
          <h5>License duration:</h5>
          <b>{{ data.offer.commitment }}</b>
          <!-- <a>Add user</a> -->
       </div>
       <!-- <div>
          <h5>Teaching Assistants:</h5>
          <b>{{ data.offer.teachingAssistants }}</b>
          <a>Add user</a>
       </div> -->
       <!-- <div>
          <h5>Academic Integrity Officer:</h5>
          <b>{{ data.offer.academicIntegrityOfficers }}</b>
          <a>Add user</a>
       </div> -->
       <!-- <div>
          <h5>Subscriptions:</h5>
          <b>{{ data.offer.subscriptions == 1 ? true : false }}</b>
          <a>Add user</a>
       </div> -->
       <!-- <div>
          <h5>Thesis:</h5>
          <div>
             <div>
                <b>Bachelor: </b>
                <span> {{ data.offer.bachelor }}</span>
             </div>
             <div>
                <b>Master: </b>
                <span> {{ data.offer.master }}</span>
             </div>
             <div>
                <b>Phd: </b>
                <span> {{ data.offer.phd }}</span>
             </div>
          </div>
          <a>Add user</a>
       </div> -->
       <!-- <div>
          <h5>Document upload:</h5>
          <b>{{ data.offer.professorIndividualUploads }}</b>
          <a>Add user</a>
       </div> -->
       <!-- <div>
          <h5>Crosslingual:</h5>
          <div>
             <span
                ><input
                   [disabled]="true"
                   type="checkbox"
                   id="assignment"
                   [checked]="data.offer.crossLingual['assignments']"
                /><label for="assignment">Assignment Level</label></span
             >
             <span
                ><input
                   [disabled]="true"
                   type="checkbox"
                   id="thesis"
                   [checked]="data.offer.crossLingual['thesis']"
                /><label for="thesis">Thesis</label></span
             >
             <span
                ><input
                   [disabled]="true"
                   type="checkbox"
                   id="professorUpload"
                   [checked]="data.offer.crossLingual['professorUploads']"
                /><label for="professorUpload"
                   >Document Uploads</label
                ></span
             >
          </div>
          <a (click)="changeEditInput('crossLingual')">Change</a>
       </div> -->
       <div>
          <h5>Payment preference:</h5>
          <div>
             <span
                ><input
                   name="billingPlan"
                   [disabled]="true"
                   type="radio"
                   id="monthly"
                   [checked]="data.offer.billingPlan == 'Monthly'"
                /><label for="monthly">Monthly</label></span
             >
             <span
                ><input
                   name="billingPlan"
                   [disabled]="true"
                   type="radio"
                   id="annual"
                   [checked]="data.offer.billingPlan == 'Annually'"
                /><label for="annual">Annual</label></span
             >
             <span
                ><input
                   name="billingPlan"
                   [disabled]="true"
                   type="radio"
                   id="full"
                   [checked]="data.offer.billingPlan == 'OnePayment'"
                /><label for="full">Full</label></span
             >
          </div>
          <!-- <a (click)="changeEditInput('billingPlan')">Change</a> -->
       </div>
       <!-- <div>
          <h5>Support:</h5>
          <div>
             <span
                ><input
                   name="support"
                   [disabled]="true"
                   type="radio"
                   id="basic"
                   [checked]="data.offer.support == 'Basic'"
                /><label for="basic">Basic</label></span
             >
             <span
                ><input
                   name="support"
                   [disabled]="true"
                   type="radio"
                   id="Standard"
                   [checked]="data.offer.support == 'Standard'"
                /><label for="Standard">Standard</label></span
             >
             <span
                ><input
                   name="support"
                   [disabled]="true"
                   type="radio"
                   id="Premium"
                   [checked]="data.offer.support == 'Premium'"
                /><label for="Premium">Premium</label></span
             >
          </div>
       </div> -->
    </div>
 </div>
 <div class="emailConsent">
    <input
    formControlName="sentEmail"
    name="emails"
    id="emails"
    type="checkbox"
 />
 <label for="emails"></label>
 Yes, I would like crossplag team to contact me for this offer
 </div>
    <button
    [disabled]="contactUs.invalid"
    [ngClass]="{
       disable: contactUs.invalid
    }"
    class="submit"
    type="submit"
    (click)="sent()"
 >
    sent
 </button>
 </form>
 
</div>