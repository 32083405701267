<div class="edit-faculty">
   <div class="edit-faculty-title">
      <h4>Edit Faculty</h4>
   </div>
   <form [formGroup]="facultyEdit">
      <div class="editimi-fakultetit-inputs">
         <div class="inside-inputs">
            <label for="facultyName">Name:</label>
            <input formControlName="facultyName" type="text" />
            <div
               *ngIf="
                  facultyEdit.controls.facultyName.touched &&
                  facultyEdit.controls.facultyName.invalid
               "
               class="invalid"
            >
               Faculty Name is required*
            </div>
            <div
               *ngIf="facultyEdit.controls.facultyName.errors?.minlength"
               class="invalid"
            >
               Faculty name should contain at least 3 characters!
            </div>
         </div>
         <div class="inside-inputs">
            <label for="Description">Description:</label>
            <input formControlName="facultyDescription" type="text" />
            <div
               *ngIf="
                  facultyEdit.controls.facultyDescription.touched &&
                  facultyEdit.controls.facultyDescription.invalid
               "
               class="invalid"
            >
               Description is required*
            </div>

            <div
               *ngIf="facultyEdit.controls.facultyDescription.errors?.minlength"
               class="invalid"
            >
               Faculty Description should contain at least 3 characters!
            </div>
         </div>
      </div>
      <div class="buttons">
         <a class="fshij-link" (click)="delete()">Delete</a>
         <button
            [disabled]="facultyEdit.invalid"
            [ngClass]="{
               disable: facultyEdit.invalid
            }"
            type="submit"
            class="editButton"
            (click)="update()"
         >
            SAVE
         </button>
      </div>
   </form>
</div>
