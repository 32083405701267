<section class="flex register-profesori container">
   <main>
      <h4 class="bold">Register faculties</h4>
      <div class="nav">
         <a (click)="toggleForm('manual')" [ngClass]="{ active: this.excel === false }"
            >Manual form</a
         >
         <a (click)="toggleForm('bulk')" [ngClass]="{ active: this.excel === true }" class="disable"
            >Bulk form</a
         >
      </div>
      <ng-container *ngIf="this.excel === false">
         <form [formGroup]="facultyRegister">
            <ng-container formArrayName="faculties">
               <ng-container
                  *ngFor="let faculty of facultiesForm.controls; let i = index"
                  [formGroupName]="i"
               >
                  <div class="facultyInfo">
                     <div style="width: 100%">
                        <h5>Faculty name</h5>
                        <input formControlName="facultyName" type="text" />
                        <div
                           *ngIf="
                              facultiesForm.controls[i].controls.facultyName
                                 .touched &&
                              facultiesForm.controls[i].controls.facultyName
                                 .invalid
                           "
                           class="invalid"
                        >
                           Faculty Name is required*
                        </div>
                        <div
                           *ngIf="
                              facultiesForm.controls[i].controls.facultyName
                                 .errors?.minlength
                           "
                           class="invalid"
                        >
                           Faculty name should contain at least 3 characters!
                        </div>
                        <h5>Description</h5>
                        <textarea
                           name=""
                           formControlName="facultyDescription"
                           id=""
                           cols="30"
                           rows="2"
                        ></textarea>
                        <div
                           *ngIf="
                              facultiesForm.controls[i].controls
                                 .facultyDescription.touched &&
                              facultiesForm.controls[i].controls
                                 .facultyDescription.invalid
                           "
                           class="invalid"
                        >
                           Description is required*
                        </div>

                        <div
                           *ngIf="
                              facultiesForm.controls[i].controls
                                 .facultyDescription.errors?.minlength
                           "
                           class="invalid"
                        >
                           Faculty Description should contain at least 3
                           characters!
                        </div>
                     </div>
                     <div>
                        <ng-container *ngIf="facultiesForm.controls.length > 1">
                           <button
                              class="removeFaculty"
                              (click)="this.removeFaculty(i)"
                           >
                              - Remove
                           </button>
                        </ng-container>
                     </div>
                  </div>
               </ng-container>
            </ng-container>
            <div>
               <button class="addMore" (click)="this.addFaculty()">
                  + Add more faculties
               </button>
            </div>
            <div class="center" [ngClass]="{ flex: dialogRef }">
               <button
                  class="submit cancelButton"
                  *ngIf="dialogRef"
                  (click)="closeModal()"
               >
                  Cancel
               </button>
               <button
                  [disabled]="facultyRegister.invalid"
                  [ngClass]="{
                     disable: facultyRegister.invalid
                  }"
                  type="submit"
                  (click)="submit()"
                  class="submit"
               >
                  Register
               </button>
            </div>
         </form>
      </ng-container>
      <ng-container *ngIf="this.excel === true">
         <div class="content">
            <div>
               <select class="border" name="" id="">
                  <option value="">1</option>
                  <option value="">2</option>
               </select>
               <div class="border" (click)="downloadTemplate()">
                  <h6>Download Template</h6>
                  <div>
                     <i class="fas fa-download"></i>
                     <p>
                        Download and fill the relevant information within the
                        template
                     </p>
                  </div>
               </div>
            </div>
            <div>
               <select class="border" name="" id="">
                  <option value=""></option>
                  <option value=""></option>
               </select>
               <div class="border" (click)="upload()" style="cursor: pointer">
                  <div>
                     <h6>Upload Template</h6>
                     <i class="fas fa-upload"></i>
                  </div>
                  <p>
                     Upload the filled template to migrate your data
                     automatically
                  </p>
               </div>
               <input
                  class="dropzone"
                  type="file"
                  name=""
                  id=""
                  (change)="onFileSelected($event)"
                  accept=".xlsx"
                  style="display: none"
               />
            </div>
         </div>
      </ng-container>
   </main>
</section>
