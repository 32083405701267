<div class="cards">
   <div routerLink="faculties" routerLinkActive="active" class="shadowBorder">
      <span
         ><img src="../../../../assets/images/newIcons/18.Faculty.png" alt="" />
         <img src="../../../../assets/images/newIcons/18.Faculty_White.png" alt="" />
      </span>
      <div>
         Faculties
         <span> {{ this.overview?.nrOfFaculties || 0 }} </span>
      </div>
   </div>
   <div routerLink="departments" routerLinkActive="active" class="shadowBorder">
      <span
         ><img src="../../../../assets/images/newIcons/19.Department_Color.png" alt="" />
         <img src="../../../../assets/images/newIcons/19.Department_White.png" alt="" />
      </span>
      <div>
         Departments
         <span>{{ this.overview?.nrOfDepartments || 0 }}</span>
      </div>
   </div>
   <div routerLink="subjects" routerLinkActive="active" class="shadowBorder">
      <span
         ><img src="../../../../assets/images/newIcons/1.Subject_Color.png" alt="" />
         <img src="../../../../assets/images/newIcons/1.Subject_White.png" alt="" />
      </span>
      <div>
         Subjects
         <span>{{ this.overview?.nrOfSubjects || 0 }}</span>
      </div>
   </div>
   <div routerLink="assignments" routerLinkActive="active" class="shadowBorder">
      <span
         ><img src="../../../../assets/images/newIcons/2.Assignments_Color.png" alt="" />
         <img src="../../../../assets/images/newIcons/2.Assignments_white.png" alt="" />
      </span>
      <div>
         Assignments
         <span>{{ this.overview?.nrOfAssignments || 0 }}</span>
      </div>
   </div>
</div>
<router-outlet></router-outlet>
