<div *ngIf="showSubjects" class="cloneSubjects">
   <h4>Clone your subject</h4>
   <div  class="subjectsFilters">
     <a (click)="changeFilters()">Change your selections</a>
     <div class="choose-generation">
      <span>Select the academic year you want to renew subjects</span>
      <select  [(ngModel)]="generationToChange"
      class="border white-bg select-adm"
   >
      <option selected disabled>Choose Generation!</option>
      <option value="g1819">2018/2019</option>
      <option value="g1920">2019/2020</option>
      <option value="g2021">2020/2021</option>
      <option value="g2122">2021/2022</option>
      <option value="g2223">2022/2023</option>
      <option value="g2324">2023/2024</option>
      <option value="g2425">2024/2025</option>
      <option value="g2526">2025/2026</option>
   </select>
     </div>
   </div>
   <div *ngIf="this.courses?.length == 0" class="subjectsZeroState">
      You have no subjects with these filters 
   </div>
   <table *ngIf="this.courses?.length > 0">
      <tr>
         <th><h5 class="bold left">Subject name</h5></th>
         <th><h5 class="bold">Generation</h5></th>
         <th><h5 class="bold">Faculty</h5></th>
         <th><h5 class="bold">Department</h5></th>
         <th><h5 class="bold">Professors</h5></th>
      </tr>
      <ng-container *ngFor="let course of this.courses; let i = index">
         <tr
            class="shadow"
            [ngClass]="{ error: course.error === true }"
            *ngIf="course.hide === false"
         >
            <td>
               <h5 class="left">{{ course.title }}</h5>
            </td>
            <td>
               <h5>{{ '20'+ course.generation.substring(1, 3) }}/{{ '20'+ course.generation.substring(3, 5) }}</h5>
            </td>
            <td>
               <h5>{{ course.Department.Faculty.name }}</h5>
            </td>
            <td>
               <h5>{{ course.Department.name }}</h5>
            </td>
            <td>
               <ngx-select-dropdown
                  [(ngModel)]="course.professors"
                  [config]="this.config"
                  [options]="course.professorsToAdd"
                  [multiple]="true"
               ></ngx-select-dropdown>
            </td>
            <td>
               <h5 class="remove" (click)="this.removeIndex(i)">Delete</h5>
            </td>
         </tr>
      </ng-container>
   </table>
   <div class="buttons">
      <div>
         <span class="submit" (click)="save()">Save</span>
      </div>
      <button (click)="closeModal()">Cancel</button>
   </div>
</div>

<div class="cloneSubjectsFilters" *ngIf="!showSubjects">
   <h1>Subject Wizard</h1>
   <p>The Subject Wizard main goal is to help you in the process of prepare your academic year subjects for a new academic year.
       Through the Subject Wizard you are able to renew the structure of subjects and by who they are delivered in just a few clicks. 
      <br> <br> Steps to follow for a smooth subject renewal process: <br> <br>
            1. Choose which Academic Year you want to use as a reference. <br> 
            2. Choose which Faculty you want to renew subjects for. <br>
            3. Choose which Department you want to renew subjects for. <br>
            4. Press “Submit” when you are ready. <br> <br>
            If you need assistance on this process please read our <a href="">Subject Wizard Documentation</a> or contact us at <a href="mailto:support@crossplag.com">support@crossplag.com</a>.
   </p>
   <div class="selectors">
      <div class="generationFilter">
         <span>Choose generation</span>
         <select
            [(ngModel)]="selectedGeneration"
            name=""
            id=""
         >
            <option value="-1" selected disabled>
               Choose Generation!
            </option>
            <option value="g1819">2018/2019</option>
            <option value="g1920">2019/2020</option>
            <option value="g2021">2020/2021</option>
            <option value="g2122">2021/2022</option>
            <option value="g2223">2022/2023</option>
            <option value="g2324">2023/2024</option>
            <option value="g2425">2024/2025</option>
            <option value="g2526">2025/2026</option>
            <option value="0">All</option>
         </select>
         <div class="invalid" *ngIf="showError == true && (selectedGeneration == '-1' || selectedGeneration == '')"
            >This field is required!
         </div>
      </div>
      <div>
         <span>Select faculty</span>
         <select [(ngModel)]="facultyId" (change)="selectedFaculty()">
            <option value="-1" selected disabled>
               Filter by faculty
            </option>
            <ng-container *ngFor="let faculty of this.faculties">
               <option value="{{ faculty.id }}">
                  {{ faculty.name }}
               </option>
            </ng-container>
            <option value="">All</option>
         </select>
         <div class="invalid" *ngIf="showError == true && (facultyId == '' || facultyId == '-1')"
            >This field is required!
         </div>
      </div>
      <div>
         <span>Select department</span>
         <select
            disabled
            *ngIf="facultyId == '' || facultyId == '-1'"
            name=""
            id=""
         >
            <option value="">Select faculty first</option>
         </select>
         <select
            *ngIf="facultyId !== '' && facultyId !== '-1'"
            [(ngModel)]="departmentId"
         >
            <option value="-1" selected disabled>
               Filter by department
            </option>
            <ng-container *ngFor="let department of this.departments">
               <option value="{{ department.id }}">
                  {{ department.name }}
               </option>
            </ng-container>
            <option value="">All</option>
         </select>
         <div class="invalid" *ngIf="showError == true && (departmentId == '-1' || departmentId == '')"
            >This field is required!
         </div>
      </div>
   </div>
   <button class="submit" (click)="submit()">Submit</button>
</div>
