import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

@Injectable({
   providedIn: 'root',
})
export class ThesisService {
   constructor(private http: HttpClient) {}
   /**
    * Service used to call api that return thesis information of logged in admin.
    * @returns
    */
   institutionThesisUsage(page, name, level, faculty, department) {
      return this.http
         .get<any>(
            `${environment.apiUrl}/thesis/usage/?page=${page}` +
               (name ? `&name=${name}` : '') +
               (level ? `&level=${level}` : '') +
               (faculty ? `&faculty=${faculty}` : '') +
               (department ? `&department=${department}` : '')
         )
         .pipe(
            map((data) => {
               return data;
            })
         );
   }

   createThesis(data) {
      let studentId = data.studentId;
      let name = data.name;
      let startDate = new Date(data.startDate);
      let endDate = new Date(data.endDate);
      let citations = data.citations;
      let numberOfSubmisions = parseInt(data.submissionsNumber);
      let level = data.level;
      let originalLanguage = data.originalLanguage;
      let translatedLanguage = data.translatedLanguage;

      return this.http
         .post<any>(`${environment.apiUrl}/thesis`, {
            studentId,
            name,
            startDate,
            endDate,
            citations,
            numberOfSubmisions,
            level,
            originalLanguage,
            translatedLanguage,
         })
         .pipe(
            map((data) => {
               return data;
            })
         );
   }

   getStudentName(index) {
      return this.http
         .get<any>(`${environment.apiUrl}/index/${index}/student`, {})
         .pipe(
            map((data) => {
               return data;
            })
         );
   }

   getThesis(userId, status, name, page) {
      return this.http
         .get<any>(
            `${environment.apiUrl}/thesis/?page=${page}` +
               (name ? `&name=${name}` : '') +
               (status ? `&status=${status}` : '')
         )
         .pipe(
            map((data) => {
               return data;
            })
         );
   }

   editThesis(data, thesisId) {
      let studentId = data.studentId;
      let name = data.name;
      let startDate = new Date(data.startDate);
      let endDate = new Date(data.endDate);
      let citations = data.citations;
      let numberOfSubmisions = parseInt(data.submissionsNumber);
      let level = data.level;
      let originalLanguage = data.originalLanguage;
      let translatedLanguage = data.translatedLanguage;
      return this.http
         .put<any>(`${environment.apiUrl}/thesis/${thesisId}`, {
            studentId,
            name,
            startDate,
            endDate,
            citations,
            numberOfSubmisions,
            level,
            originalLanguage,
            translatedLanguage,
         })
         .pipe(
            map((data) => {
               return data;
            })
         );
   }

   deleteThesis(thesisId) {
      return this.http
         .delete<any>(`${environment.apiUrl}/thesis/${thesisId}`, {})
         .pipe(
            map((data) => {
               return data;
            })
         );
   }

   getStudentThesis() {
      return this.http
         .get<any>(`${environment.apiUrl}/student/thesis`, {})
         .pipe(
            map((data) => {
               return data;
            })
         );
   }
}
