<header>
   <div class="flex container">
      <img
         routerLink="dashboard"
         class="logo"
         src="../../assets/images/Crossplag_Logo.png"
         alt=""
      />
      <div class="flex">
         <h5 class="totalCredits" *ngIf="user?.roleId == 2">
            <span 
               >Available Credits: {{ user?.wordsToUse }}</span
            >
            <span *ngIf="user?.institutionId !== null"
               >Available Documents: {{ user?.documentsToUse }}</span
            >
            <span *ngIf="user?.roleId == 6" style="color: white">.</span>
         </h5>
         <button routerLink="register" class="reportBtn" *ngIf="user?.roleId ==  5">
            Join a class
         </button>
         <div class="flex relative">
            <i class="fas fa-user-alt"></i>
            <h5 (click)="expandMenu()" #name>
               {{ user?.name }}
            </h5>
            <div class="log-out">
               <i
                  #chevron
                  (click)="expandMenu()"
                  class="fas fa-chevron-down"
                  [ngClass]="{ chevronUp: expandedMenu }"
               ></i>
            </div>
            <div class="menu" [ngClass]="{ expandedMenu: expandedMenu }">
               <h3 routerLink="profile" routerLinkActive="active">
                  <i class="fas fa-user"></i> Account
               </h3>
               <h3 (click)="signOut()">
                  <i class="fas fa-sign-out-alt"></i> Log out
               </h3>
            </div>
         </div>
      </div>
   </div>
</header>
<div [ngClass]="{removeInfoIndex:removeInfoIndex}" *ngIf="user?.Institution?.InstitutionLicense?.length == 0 && user.roleId == 3" class="notifyInstitution">
  <div></div>
  <span> We are reviewing your institutional information for a free test of Crossplag, please be patient! If you have any question or additional requests contact us at support@crossplag.com.</span>
   <i (click)="removeInfo()" class="far fa-times-circle"></i>
</div>

<div [ngClass]="{removeInfoIndex:removeInfoIndex}" *ngIf="user.roleId == 2" class="notifyInstitution">
   <div></div>
   <span>Introducing our new AI Content Detector! <a routerLink="/individual/detector">Try it out for free now.<i class="fas fa-external-link-alt"></i></a> </span>
    <i (click)="removeInfo()" class="fas fa-times-circle"></i>
 </div>

<div [ngClass]="{removeInfoIndex:removeInfoIndex}" *ngIf="user?.Institution?.InstitutionLicense[0]?.offerId == 'free' && user.roleId == 3" class="notifyInstitution">
   <span></span>
   <span>You are on free trial, contact us to upgrade</span>
   <i (click)="removeInfo()" class="far fa-times-circle"></i>
</div>