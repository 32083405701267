import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as userActions from '../../../authModule/state/actions/authentication.actions';
import { getCurrentUser } from 'src/app/authModule/state/authentication.selectors';

@Component({
   selector: 'app-how-to-use',
   templateUrl: './how-to-use.component.html',
   styleUrls: ['./how-to-use.component.scss'],
})
export class HowToUseComponent implements OnInit, OnDestroy {
   videoIndex;
   guideIndex;
   pdfSrc;
   user;
   user$;
   constructor(private store: Store) {}
   ngOnDestroy(): void {
      this.user$.unsubscribe();
   }

   ngOnInit(): void {
      this.store.dispatch(userActions.currentUser());

      this.user$ = this.store.select(getCurrentUser).subscribe((data) => {
         if (data !== null) {
            this.user = data;
         }
      });

      if (this.user.institutionId !== null && this.user.roleId === 6) {
         this.pdfSrc =
            'https://crossplag-asset-migration.s3.eu-central-1.amazonaws.com/Guides/Vleresuesi_Guide.pdf';
      } else if (this.user.institutionId !== null && this.user.roleId === 2) {
         this.pdfSrc =
            'https://crossplag-asset-migration.s3.eu-central-1.amazonaws.com/Guides/Studenti_Guide.pdf';
      } else if(this.user.institutionId == null && this.user.roleId === 2) {
         this.pdfSrc =
         'https://crossplag-asset-migration.s3.eu-central-1.amazonaws.com/Guides/User%2BGuide%2B-%2BIndividual.pdf';
      }
      this.videoIndex = -1;
      this.guideIndex = 1;
   }
   video() {
      this.videoIndex = 1;
      this.guideIndex = -1;
   }
   guide() {
      this.guideIndex = 1;
      this.videoIndex = -1;
   }
}
