<div class="documents-2 shadow white-bg">
   <div
      *ngIf="
         !(
            this.totalDepartments == 0 &&
            departmentName == undefined &&
            faculty == '-1'
         )
      "
      class="filtersContainer"
   >
      <h4>Overview</h4>
      <div class="selectors">
         <div>
            <select [(ngModel)]="faculty" (change)="selectedFaculty()">
               <option value="-1" selected disabled>
                  Filter by faculty
               </option>
               <option
                  *ngFor="let faculty of this.faculties"
                  value="{{ faculty.id }}"
               >
                  {{ faculty.name }}
               </option>
               <option value="">All</option>
            </select>
         </div>
         <div>
            <input
               placeholder="Search by name"
               [(ngModel)]="departmentName"
               (ngModelChange)="titleFilter()"
               type="text"
               name="searchByName"
            />
         </div>
         <button class="reportBtn" (click)="resetFilter()">Reset</button>
      </div>
   </div>

   <div
      *ngIf="this.totalDepartments != 0"
      class="tableWithExpandedInfoDepartments tablewithRatios"
   >
      <ng-container
         *ngFor="
            let department of this.departments
               | paginate
                  : {
                       itemsPerPage: 5,
                       currentPage: this.page,
                       totalItems: this.totalDepartments
                    };
            let i = index
         "
      >
         <div>
            <div>
               <h4 class="blueText bold">{{ department.name }}</h4>
               <div>
                  <div>
                     <span>Professors</span>
                     <span>Students</span>
                     <span>Subjects</span>
                     <span>Assignments</span>
                     <span>Submissions</span>  
                     <!-- <span>Professors submissions</span> -->
                     <span>Thesis</span>
                     <span>Document language<br/> similarity</span>
                     <span>Translation language<br/> similarity</span>
                  </div>
                  <div>
                     <span
                        (click)="
                           professorFilter(department.id, department.facultyId)
                        "
                        class="bold clickable"
                        >{{ department.professors }}</span
                     >
                     <span
                        (click)="
                           studentsFilter(department.id, department.facultyId)
                        "
                        class="bold clickable"
                        >{{ department.students }}</span
                     >
                     <span
                        (click)="
                           subjectsFilter(department.id, department.facultyId)
                        "
                        class="bold clickable"
                        >{{ department.subjects }}</span
                     >
                     <span
                        (click)="
                           assignmentFilter(department.id, department.facultyId)
                        "
                        class="bold clickable"
                        >{{ department.assignments }}</span
                     >
                     <span
                        (click)="
                           assignmentsSubmissionsFilter(
                              department.id,
                              department.facultyId
                           )
                        "
                        class="bold clickable"
                        >{{ department.assignmentsSubmissions }}</span
                     >
                     <!-- <span
                        (click)="
                           professorsSubmissionsFilter(
                              department.id,
                              department.facultyId
                           )
                        "
                        class="bold clickable"
                        >{{ department.professorsSubmissions }}</span
                     > -->
                     <span
                        (click)="
                           thesisFilter(department.id, department.facultyId)
                        "
                        class="bold clickable"
                        >{{ department.thesis }}</span
                     >
                     <span class="bold">{{
                        department.submissions._avg.originalPercentage === null
                           ? 0
                           : department.submissions._avg.originalPercentage.toFixed(
                                2
                             )
                     }}</span>
                     <span class="bold">{{
                        department.submissions._avg.translatedPercentage ===
                        null
                           ? 0
                           : department.submissions._avg.translatedPercentage.toFixed(
                                2
                             )
                     }}</span>
                  </div>
               </div>
               <a
                  *ngIf="expandInfo !== i"
                  class="blueText underline vold"
                  (click)="expand(i)"
                  >Expand</a
               >
               <a
                  *ngIf="expandInfo === i"
                  class="blueText underline vold"
                  (click)="expand(i)"
                  >Collapse</a
               >
            </div>
            <div
               [ngClass]="{ expandedInfo: expandInfo == i }"
               class="moreInfo"
               style="grid-template: none !important"
            >
<div class="details">
   <span>
      Most used language:
      <b>{{
         department.language === undefined
            ? '-'
            : department.language[0]
      }}</b></span
   >
   <span>
      Bachelor theses: <b>{{ department.bachelor }}</b></span
   >
   <span>
      Master theses: <b>{{ department.master }}</b></span
   >
   <span>
      Ph.D. Theses: <b>{{ department.phd }}</b></span
   >
   <span>
      High similarity submissions:
      <b>{{ department.highSimilaritySubmissions }}</b></span
   >
   <span>
      Medium similarity submissions:
      <b>{{
         department.mediumSimilaritySubmissions
      }}</b></span
   >
   <span>
      Low similarity submissions:
      <b>{{ department.lowSimilaritySubmissions }}</b></span
   >
</div>
<div class="ratios">
Ratios
</div>
<div class="details">
   <span>
      Professor-to-Subject:
      <b
         >{{ department.professors }} : {{
            department.subjects
         }}</b
      ></span
   >
   <span>
      Professor-to-Student:
      <b
         >{{ department.professors }} : {{
            department.students
         }}</b
      >
   </span>
   <span>
      Professor-to-Submission:<b
         > {{ department.professors }} : {{
            department.submissions._count
         }}</b
      >
   </span>
   <span>
      Student-to-Subject:
      <b
         >{{ department.students }} : {{
            department.subjects
         }}</b
      >
   </span>
   <span>
      Student-to-Submission:
      <b
         >{{ department.students }} : {{
            department.submissions._count
         }}</b
      ></span
   >
</div>
               </div>
            </div>
      </ng-container>
   </div>
   <div
      class="zeroState"
      *ngIf="
         this.totalDepartments == 0 &&
         (departmentName != undefined || faculty != '-1')
      "
   >
      No departments found
   </div>
   <pagination-controls
      *ngIf="this.totalDepartments != 0"
      class="num"
      (pageChange)="changePage($event)"
      responsive="true"
      previousLabel=""
      nextLabel=""
   ></pagination-controls>
   <div
      class="zero-state"
      *ngIf="
         this.totalDepartments == 0 &&
         departmentName == undefined &&
         faculty == '-1'
      "
   >
      <img src="../../assets/images/icona-image.png" alt="" />
      <h5 class="bold" style="z-index: 1;">There are no departments registered in your institution. 
         <a 
           routerLink ="/administrator/register-module/department-register" 
           routerLinkAction="active">Click here</a>
          to begin registering your first department.</h5>
   </div>
</div>
