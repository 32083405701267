import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

/**
 * Service used for user entity.
 */
@Injectable({
   providedIn: 'root',
})
export class UserService {
   /**
    * Variable used to store user from store.
    */
   user;
   /**
    * Observable used to subscribe to user state store.
    */
   userObservable;
   /**
    * User Service Constructor
    * @param http
    * @param authService
    */
   constructor(private http: HttpClient) {}

   /**
    * Method used to call sign up user api
    * @param firstName
    * @param lastName
    * @param email
    */
   signUp(
      firstName: string,
      lastName: string,
      email: string,
      institutionName: String,
      marketing: boolean,
      country: String,
      role: String,
      currentSystem:String,
      company: String,
      businessMessage: String
   ) {
      let institutionDescription="test"
      return this.http
         .post<any>(`${environment.apiUrl}/users`, {
            firstName,
            lastName,
            email,
            institutionName,
            marketing,
            role,
            country,
            currentSystem,
            institutionDescription,
            company,
            businessMessage
         })
         .pipe(
            map((data) => {
               return data;
            })
         );
   }
   /**
    * Method used to call reset user password api
    * @param currentPassword
    * @param password
    * @param repeatPassword
    */
   resetPassword(
      currentPassword: string,
      password: string,
      repeatPassword: string
   ) {
      return this.http
         .put<any>(`${environment.apiUrl}/users/reset-password`, {
            currentPassword,
            password,
            repeatPassword,
         })
         .pipe();
   }
   updateUserInfo(firstName: string, lastName: string) {
      return this.http
         .put<any>(`${environment.apiUrl}/users/update-user-info`, {
            firstName,
            lastName,
         })
         .pipe();
   }
   /**
    * Method used to verify user account
    * @param password
    * @param repeatPassword
    * @param token
    * @param provider
    */
   verifyAccount({ token, password, repeatPassword,name,index }: any) {
      return this.http
         .post<any>(`${environment.apiUrl}/account-verification`, {
            name,
            index,
            password,
            repeatPassword,
            token,
         })
         .pipe();
   }
   /**
    * Method used to send message in email from contact us forms.
    * Used for the help-center and for the bank from.
    * @param name
    * @param email
    * @param message
    * @param userId
    * @param type
    */
   sendMessage(
      name: string,
      email: string,
      phoneNumber: string,
      message: string,
      userId: string,
      type: string
   ) {
      return this.http
         .post<any>(`${environment.apiUrl}/user/${userId}/emailMessage`, {
            name,
            email,
            phoneNumber,
            message,
            type,
         })
         .pipe();
   }

   /**
    * Method is used to call api for student registration to given faculty and department id with all other information.
    * @param studentName
    * @param studentFaculty
    * @param studentDepartment
    * @param studentIndexID
    * @param studentEmail
    * @returns
    */
   registerStudent(
      studentName: string,
      studentFaculty: string,
      studentDepartment: string,
      studentIndexID: string,
      studentEmail: string,
   ) {
      return this.http
         .post<any>(`${environment.apiUrl}/student`, {
            studentName,
            studentFaculty,
            studentDepartment,
            studentIndexID,
            studentEmail,
         })
         .pipe(
            map((data) => {
               return data;
            })
         );
   }

   /**
    * Service is used to create a professor for a given faculty and department.
    * @param name
    * @param facultyId
    * @param departmentId
    * @param courses
    * @param email
    * @returns
    */
   createProfessor(
      name: string,
      facultyId: string,
      departmentId: string,
      courses: any,
      email: string,
      wordsToUse: number
   ) {
      return this.http
         .post<any>(`${environment.apiUrl}/professors`, {
            name,
            facultyId,
            departmentId,
            courses,
            email,
            wordsToUse,
         })
         .pipe();
   }
   /**
    * Service is use to retrieve all professor of institution.
    * @returns
    */
   institutionProfessors(page?, faculty?, department?, professorName?) {
      return this.http
         .get<any>(
            `${environment.apiUrl}/professors/?page=${page}` +
               (faculty ? `&faculty=${faculty}` : '') +
               (department ? `&department=${department}` : '') +
               (professorName ? `&professorName=${professorName}` : '')
         )
         .pipe();
   }

   /**
    * Service is used to call api that return information for specific User.
    * @param userId
    * @returns
    */
   userInfo(userId: string) {
      return this.http.get<any>(`${environment.apiUrl}/user/${userId}`).pipe(
         map((data) => {
            return data;
         })
      );
   }
   /**
    * Service is used to call api that will submit edited information of specific User.
    * @returns
    */

   editUser(
      userId: string,
      userName: string,
      userEmail: string,
      userFaculty: string,
      userDepartment: string,
      userStatus: number,
      increaseWords: boolean,
      professorWordsToAllocate: number,
      userIndexId?: string,
      userSubjects?: string
   ) {
      return this.http
         .put<any>(`${environment.apiUrl}/user`, {
            userId,
            userName,
            userEmail,
            userFaculty,
            userDepartment,
            userSubjects,
            userStatus,
            increaseWords,
            professorWordsToAllocate,
            userIndexId,
         })
         .pipe(
            map((data) => {
               return data;
            })
         );
   }

   /**
    * Service is used to call api that will remove a specific User.
    * @param professorId
    * @returns
    */
   removeUser(userId: string) {
      return this.http.delete<any>(`${environment.apiUrl}/user/${userId}`).pipe(
         map((data) => {
            return data;
         })
      );
   }

   /**
    * Service is use to retrieve all students of institution.
    * @returns
    */
   institutionStudents(page?, faculty?, department?, name?) {
      return this.http
         .get<any>(
            `${environment.apiUrl}/students/?page=${page}` +
               (faculty ? `&faculty=${faculty}` : '') +
               (department ? `&department=${department}` : '') +
               (name ? `&name=${name}` : '')
         )
         .pipe();
   }

   inviteStudent(
      studentName: string,
      studentEmail: string,
      documentLanguage: string,
      translateTo: string
   ) {
      return this.http.post<any>(`${environment.apiUrl}/invite/student`, {
         studentName,
         studentEmail,
         documentLanguage,
         translateTo,
      });
   }

   deleteStudent( studentId ) {
      return this.http.delete<any>(`${environment.apiUrl}/student/${studentId}/professor/delete`);
   }

   administratorIndividuals(page) {
      return this.http
         .get<any>(
            `${environment.apiUrl}/administrator/individuals/?page=${page}`
         )
         .pipe();
   }

   editAdministratorIndividual(
      id: string,
      studentName: string,
      studentEmail: string,
      documentLanguage: string,
      translateTo: string
   ) {
      return this.http
         .put<any>(`${environment.apiUrl}/administrator/individual/${id}`, {
            studentName,
            studentEmail,
            documentLanguage,
            translateTo,
         })
         .pipe();
   }

   checkReSubmissionsNumber() {
      return this.http
         .get<any>(`${environment.apiUrl}/individual/reSubmissions`)
         .pipe();
   }

   checkEmail(email) {
      return this.http
         .post<any>(`${environment.apiUrl}/email/validity`, { email })
         .pipe();
   }

   checkIndex(index) {
      return this.http
         .post<any>(`${environment.apiUrl}/student/index/validity`, { index })
         .pipe();
   }

   inviteStudents( emails,subjectId,checkEmails )
    {
      return this.http.post<any>(`${environment.apiUrl}/invite/students`, {
        emails,
        subjectId,
        checkEmails,
      });
   }


resendConfirmationEmail(id) {
   return this.http
      .post<any>(`${environment.apiUrl}/user/account/confirmation/resend`, {
         id,
      })
      .pipe();
   }

   detectText(text) {
      
      
         return this.http
         .post<any>(`https://lkv1fgxnwa.execute-api.eu-central-1.amazonaws.com/production/detect`, {
            text: text,
         })
         .pipe();

      
      // return this.http
      //    .get<any>(
      //       `http://detector.crossplag.com:8080/?text=${text}`
      //    )
      //    .pipe();
   }

   aiFeedback(isCorrectClassified,aiIndex,text,userId) {
      return this.http
      .post<any>(`https://rzl58q5ew5.execute-api.eu-central-1.amazonaws.com/production/aiTextFeedback`, {
         text: text,
         aiIndex,
         isCorrectClassified,
         userId
      })
      .pipe();
   }

}