<div class="information-modal">
   <div class="shadow">
      Sentence Information
      <i mat-dialog-close class="far fa-times-circle"></i>
   </div>
   <div *ngIf="this.changeSourceView === false">
      <h4>Source:</h4>
      <a
         style="cursor: pointer"
         *ngIf="currentSource !== undefined && currentSource !== null"
         (click)="openLink()"
         >{{ currentSource.sourceUrl }}</a
      >
      <h4>Sentence:</h4>
      <p
         *ngIf="currentSentence !== null"
         [innerHTML]="currentSentence?.sourceSentence"
      ></p>
      <div>
         <button
            *ngIf="this.canEdit"
            (click)="startExclude()"
            [ngClass]="{
               disabled: this.data.sourceType
                  ? this.currentSentence?.isExcluded
                  : this.currentSentence?.isTranslationExcluded
            }"
         >
            <svg
               xmlns="http://www.w3.org/2000/svg"
               id="Capa_1"
               enable-background="new 0 0 515.556 515.556"
               height="15"
               viewBox="0 0 515.556 515.556"
               width="15"
            >
               <path
                  d="m257.778 0c-142.137 0-257.778 115.641-257.778 257.778s115.641 257.778 257.778 257.778 257.778-115.641 257.778-257.778-115.642-257.778-257.778-257.778zm-193.334 257.778c0-41.69 13.397-80.235 35.924-111.846l269.255 269.255c-31.611 22.526-70.156 35.924-111.846 35.924-106.609 0-193.333-86.723-193.333-193.333zm350.743 111.846-269.256-269.256c31.611-22.526 70.156-35.924 111.846-35.924 106.61 0 193.333 86.723 193.333 193.333 0 41.691-13.397 80.236-35.923 111.847z"
                  fill="red"
               /></svg
            >Exclude Sentence
         </button>
         <button *ngIf="this.canEdit" (click)="changeSource(currentSentence)">
            <svg
               xmlns="http://www.w3.org/2000/svg"
               xmlns:xlink="http://www.w3.org/1999/xlink"
               version="1.1"
               id="Layer_1"
               x="0px"
               y="0px"
               viewBox="0 0 330 330"
               style="enable-background: new 0 0 330 330"
               xml:space="preserve"
               width="15"
               height="15"
            >
               <path
                  id="XMLID_17_"
                  d="M315,75H51.213l49.393-49.394c5.858-5.857,5.858-15.355,0-21.213c-5.857-5.858-15.355-5.858-21.213,0l-75,75  c-5.858,5.857-5.858,15.355,0,21.213l75,75C82.322,178.535,86.161,180,90,180c3.839,0,7.678-1.465,10.606-4.394  c5.858-5.857,5.858-15.355,0-21.213L51.213,105H315c8.284,0,15-6.716,15-15S323.284,75,315,75z"
                  fill="orange"
               />
               <path
                  id="XMLID_18_"
                  d="M250.606,154.393c-5.857-5.857-15.355-5.857-21.213,0c-5.858,5.857-5.858,15.355,0,21.213L278.787,225H15  c-8.284,0-15,6.716-15,15c0,8.284,6.716,15,15,15h263.787l-49.393,49.394c-5.858,5.857-5.858,15.355,0,21.213  C232.322,328.535,236.161,330,240,330c3.839,0,7.678-1.465,10.606-4.394l75-75c5.858-5.857,5.858-15.355,0-21.213L250.606,154.393z"
                  fill="orange"
               />
               <g></g>
               <g></g>
               <g></g>
               <g></g>
               <g></g>
               <g></g>
               <g></g>
               <g></g>
               <g></g>
               <g></g>
               <g></g>
               <g></g>
               <g></g>
               <g></g>
               <g></g></svg
            >Change Source
         </button>
         <ng-container *ngIf="this.data.sourceType && this.canEdit">
            <button
               (click)="includeSentence(currentSentence)"
               [ngClass]="{
                  disabled: this.data.sourceType
                     ? this.currentSentence?.isExcluded === false
                     : this.currentSentence?.isTranslationExcluded === false
               }"
            >
               <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  version="1.1"
                  id="Layer_1"
                  x="0px"
                  y="0px"
                  viewBox="0 0 512.422 512.422"
                  style="enable-background: new 0 0 512.422 512.422"
                  xml:space="preserve"
                  width="15"
                  width="15"
               >
                  <g>
                     <g>
                        <g>
                           <path
                              d="M41.053,223.464c2.667,1.067,5.76,1.067,8.427-0.213l83.307-37.867c5.333-2.56,7.573-8.96,5.013-14.293     c-2.453-5.12-8.533-7.467-13.76-5.12l-58.347,26.56c27.84-83.307,105.387-138.987,194.667-138.987     c93.547,0,175.36,62.507,198.933,152c1.493,5.653,7.36,9.067,13.013,7.573c5.653-1.493,9.067-7.36,7.573-13.013     c-26.027-98.773-116.267-167.893-219.52-167.893c-98.453,0-184.107,61.44-215.04,153.387l-24.533-61.333     c-1.813-5.547-7.893-8.64-13.44-6.827c-5.547,1.813-8.64,7.893-6.827,13.44c0.107,0.427,0.32,0.853,0.533,1.28l34.027,85.333     C36.146,220.158,38.279,222.398,41.053,223.464z"
                              fill="#04c467"
                           />
                           <path
                              d="M511.773,380.904c-0.107-0.213-0.213-0.427-0.213-0.64l-34.027-85.333c-1.067-2.667-3.2-4.907-5.973-5.973     c-2.667-1.067-5.76-0.96-8.427,0.213l-83.307,37.867c-5.44,2.24-8,8.533-5.76,13.973c2.24,5.44,8.533,8,13.973,5.76     c0.213-0.107,0.427-0.213,0.64-0.32l58.347-26.56c-28.053,83.307-105.707,138.987-194.88,138.987     c-93.547,0-175.36-62.507-198.933-152c-1.493-5.653-7.36-9.067-13.013-7.573c-5.653,1.493-9.067,7.36-7.573,13.013     c25.92,98.88,116.267,167.893,219.52,167.893c98.453,0,184-61.44,215.04-153.387l24.533,61.333     c2.027,5.547,8.107,8.427,13.653,6.4C510.919,392.531,513.799,386.451,511.773,380.904z"
                              fill="#04c467"
                           />
                        </g>
                     </g>
                  </g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g></svg
               >Include Sentence
            </button>
         </ng-container>

         <ng-container *ngIf="!this.data.sourceType && this.canEdit">
            <button
               (click)="includeSentence(currentSentence)"
               [ngClass]="{
                  disabled: this.currentSentence?.isTranslationExcluded == false
               }"
            >
               <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  version="1.1"
                  id="Layer_1"
                  x="0px"
                  y="0px"
                  viewBox="0 0 512.422 512.422"
                  style="enable-background: new 0 0 512.422 512.422"
                  xml:space="preserve"
                  width="15"
                  width="15"
               >
                  <g>
                     <g>
                        <g>
                           <path
                              d="M41.053,223.464c2.667,1.067,5.76,1.067,8.427-0.213l83.307-37.867c5.333-2.56,7.573-8.96,5.013-14.293     c-2.453-5.12-8.533-7.467-13.76-5.12l-58.347,26.56c27.84-83.307,105.387-138.987,194.667-138.987     c93.547,0,175.36,62.507,198.933,152c1.493,5.653,7.36,9.067,13.013,7.573c5.653-1.493,9.067-7.36,7.573-13.013     c-26.027-98.773-116.267-167.893-219.52-167.893c-98.453,0-184.107,61.44-215.04,153.387l-24.533-61.333     c-1.813-5.547-7.893-8.64-13.44-6.827c-5.547,1.813-8.64,7.893-6.827,13.44c0.107,0.427,0.32,0.853,0.533,1.28l34.027,85.333     C36.146,220.158,38.279,222.398,41.053,223.464z"
                              fill="#04c467"
                           />
                           <path
                              d="M511.773,380.904c-0.107-0.213-0.213-0.427-0.213-0.64l-34.027-85.333c-1.067-2.667-3.2-4.907-5.973-5.973     c-2.667-1.067-5.76-0.96-8.427,0.213l-83.307,37.867c-5.44,2.24-8,8.533-5.76,13.973c2.24,5.44,8.533,8,13.973,5.76     c0.213-0.107,0.427-0.213,0.64-0.32l58.347-26.56c-28.053,83.307-105.707,138.987-194.88,138.987     c-93.547,0-175.36-62.507-198.933-152c-1.493-5.653-7.36-9.067-13.013-7.573c-5.653,1.493-9.067,7.36-7.573,13.013     c25.92,98.88,116.267,167.893,219.52,167.893c98.453,0,184-61.44,215.04-153.387l24.533,61.333     c2.027,5.547,8.107,8.427,13.653,6.4C510.919,392.531,513.799,386.451,511.773,380.904z"
                              fill="#04c467"
                           />
                        </g>
                     </g>
                  </g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g></svg
               >Include Sentence
            </button>
         </ng-container>
      </div>

      <div
         class="edit_comment"
         *ngIf="this.currentSentence?.isExcluded && this.data.sourceType"
      >
         <h3>Reason why this sentence is excluded:</h3>
         <div *ngIf="!this.editCommentStarted">
            <span *ngIf="this.currentSentence.comment"
               >{{ this.currentSentence.comment }}
            </span>
            <span *ngIf="!this.currentSentence.comment">No comment</span>
            <button
               (click)="startEdit()"
               *ngIf="!this.editCommentStarted && this.canEdit"
            >
               <i class="far fa-edit"></i> Edit
            </button>
         </div>
      </div>

      <div
         class="edit_comment"
         *ngIf="
            this.currentSentence?.isTranslationExcluded &&
            this.data.sourceType === false
         "
      >
         <h3>Reason why this sentence is excluded:</h3>
         <div *ngIf="!this.editCommentStarted">
            <span *ngIf="this.currentSentence.translateComment"
               >{{ this.currentSentence.translateComment }}
            </span>
            <span *ngIf="!this.currentSentence.translateComment"
               >No comment</span
            >
            <button (click)="startEdit()" *ngIf="!this.editCommentStarted">
               <i class="far fa-edit"></i> Edit
            </button>
         </div>
      </div>

      <div *ngIf="this.excludeButtonClicked" class="comment">
         <div>
            <textarea
               maxlength="300"
               name="comment"
               [(ngModel)]="comment"
               rows="5"
               placeholder="why do you want to exclude this sentence"
            ></textarea>
            <span> {{ this.comment.length || 0 }} / 300 </span>
         </div>
         <div>
            <button mat-dialog-close>Cancel</button>
            <button (click)="excludeSentence(currentSentence)">Finish</button>
         </div>
      </div>

      <div *ngIf="this.editCommentStarted" class="comment">
         <div>
            <textarea
               maxlength="300"
               name="comment"
               [(ngModel)]="comment"
               placeholder="Your comment here."
               rows="5"
            ></textarea>
            <span> {{ this.comment.length || 0 }} / 300 </span>
         </div>
         <div>
            <button (click)="deleteComment()">Delete</button>
            <button (click)="startEdit()">Cancel</button>
            <button (click)="updateComment()">Update</button>
         </div>
      </div>
   </div>
   <div class="sources" *ngIf="this.changeSourceView === true">
      <div>
         <div *ngFor="let source of this.SelectedSentenceSources">
<span>
   <svg
   (click)="clickedSource(source.id)"
   xmlns="http://www.w3.org/2000/svg"
   height="20px"
   viewBox="-32 0 512 512"
>
   <path
      fill="#00bbff"
      d="m235.605469 203.519531v25.742188c0 8.175781 1.632812 15.035156 4.855469 20.378906 4.445312 7.375 11.742187 11.601563 20.027343 11.601563 8.117188 0 16.183594-3.953126 23.972657-11.742188l85.023437-85.023438c18.667969-18.667968 18.667969-49.042968 0-67.710937l-85.023437-85.023437c-7.789063-7.792969-15.855469-11.742188-23.972657-11.742188-12.386719 0-24.882812 9.890625-24.882812 31.980469v23.378906c-120.710938 7.128906-220.640625 99.765625-235.492188 221.050781-.832031 6.773438 3.019531 13.257813 9.367188 15.769532 1.796875.710937 3.667969 1.054687 5.515625 1.054687 4.679687 0 9.21875-2.191406 12.109375-6.140625 39.835937-54.429688 103.796875-86.925781 171.09375-86.925781 12.46875 0 25.007812 1.125 37.40625 3.351562zm0 0"
   />
   <path
      fill="#00bbff"
      d="m438.253906 219.820312c-6.347656-2.515624-13.589844-.421874-17.625 5.089844-39.832031 54.425782-103.792968 86.921875-171.089844 86.921875-12.46875 0-25.007812-1.125-37.40625-3.351562v-25.742188c0-22.089843-12.5-31.980469-24.882812-31.980469-8.117188 0-16.183594 3.953126-23.976562 11.746094l-85.023438 85.019532c-18.664062 18.667968-18.664062 49.042968.003906 67.714843l85.019532 85.019531c7.792968 7.792969 15.859374 11.742188 23.976562 11.742188 12.382812 0 24.882812-9.890625 24.882812-31.980469v-23.378906c120.714844-7.128906 220.640626-99.765625 235.492188-221.046875.832031-6.777344-3.019531-13.261719-9.371094-15.773438zm0 0"
   />
</svg>
</span>
            <p (click)="clickedSource(source.id)">{{ source.url }}</p>
         </div>
      </div>
      <button (click)="changeSource()">
         <i class="fas fa-arrow-left"></i> Back
      </button>
   </div>
</div>
