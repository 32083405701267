<div class="edit-professor-modal">
   <div class="edit-professor-title">
      <h4>Edit professor</h4>
   </div>
   <form [formGroup]="professorEdit">
      <div class="editimi-lendes-inputs">
         <div class="fieldContainer">
            <label for="professorName">Full Name</label>
            <div>
               <input
                  id="professorName"
                  type="text"
                  formControlName="professorName"
               />
               <div
                  *ngIf="
                     professorEdit.controls.professorName.touched &&
                     professorEdit.controls.professorName.invalid
                  "
                  class="invalid"
               >
                  Full name is required
               </div>
               <div
                  *ngIf="professorEdit.controls.professorName.errors?.minlength"
                  class="invalid"
               >
                  Professor Name and Surname should contain at least 3
                  characters!
               </div>
            </div>
            <label for="Email">Email</label>
            <div>
               <input
                  id="Email"
                  type="email"
                  formControlName="professorEmail"
               />
               <!-- <div *ngIf="this.emailExists" class="invalid">
                  Email already exists, please try another one.*
               </div> -->
               <div
                  *ngIf="
                     professorEdit.controls.professorEmail.touched &&
                     professorEdit.controls.professorEmail.invalid
                  "
                  class="invalid"
               >
                  Professor Email is required*
               </div>

               <div
                  *ngIf="professorEdit.controls.professorEmail.errors?.email"
                  class="invalid"
               >
                  Professor Email is not valid*
               </div>
            </div>

            <!-- <label for="PhoneNumber">Phone Number</label>
            <div>
               <input
                  id="PhoneNumber"
                  type="text"
                  formControlName="professorPhoneNumber"
               />
               <div
                  *ngIf="
                     professorEdit.controls.professorPhoneNumber.touched &&
                     professorEdit.controls.professorPhoneNumber.invalid
                  "
                  class="invalid"
               >
                  Professor Contact number is required*
               </div>
               <div
                  *ngIf="
                     professorEdit.controls.professorPhoneNumber.errors
                        ?.minlength
                  "
                  class="invalid"
               >
                  Professor Contact number should contain at least 6 numbers!
               </div>
            </div> -->

            <label for="title">Faculty</label>
            <div>
               <select
                  formControlName="professorFaculty"
                  (change)="facultyChanged()"
               >
                  <ng-container *ngFor="let faculty of this.faculties">
                     <option value="{{ faculty.id }}" selected>
                        {{ faculty.name }}
                     </option>
                  </ng-container>
               </select>
               <div
                  *ngIf="
                     professorEdit.controls.professorFaculty.touched &&
                     professorEdit.controls.professorFaculty.invalid
                  "
                  class="invalid"
               >
                  Professor Faculty is required*
               </div>
            </div>

            <label for="title">Department</label>
            <div>
               <select formControlName="professorDepartment">
                  <ng-container *ngFor="let department of this.departments">
                     <option value="{{ department.id }}" selected>
                        {{ department.name }}
                     </option>
                  </ng-container>
               </select>
               <div
                  *ngIf="
                     professorEdit.controls.professorDepartment.touched &&
                     professorEdit.controls.professorDepartment.invalid
                  "
                  class="invalid"
               >
                  Professor Department is required*
               </div>
            </div>

            <label for="prof">Subjects</label>
            <div>
               <ngx-select-dropdown
                  [config]="this.config"
                  [options]="this.subjects"
                  [multiple]="true"
                  [formControl]="form['professorSubjects']"
               ></ngx-select-dropdown>
               <div
                  *ngIf="
                     professorEdit.controls.professorSubjects.touched &&
                     professorEdit.controls.professorSubjects.invalid
                  "
                  class="invalid"
               >
                  Professor Subjects is required*
               </div>
            </div>
            <label for="title">Status</label>
            <div>
               <select formControlName="professorStatus">
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
               </select>
            </div>
         </div>
         <div class="buttons">
            <a class="delete" (click)="delete()">Delete</a>
            <button
               class="save"
               [disabled]="professorEdit.invalid || this.emailExists"
               [ngClass]="{
                  disable: professorEdit.invalid || this.emailExists
               }"
               type="submit"
               (click)="update()"
            >
               Confirm
            </button>
         </div>
      </div>
   </form>
</div>
<div id="overlay"></div>
