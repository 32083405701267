<div
   class="report"
   [ngClass]="{
      report_expandedPdf: this.expandedPdf == true
   }"
>
   <div>
      <div class="report-header flex">
         <img src="../../../assets/images/icona-image.png" alt="" />
         <div>
            <div
               class="flex border shadow white-bg total-plag similarity-mobile"
            >
               <ng-container *ngIf="MLPlagIndex">
                  <ng-container *ngIf="this.highlighQuotes">
                     <span
                        >{{
                           (
                              currentSubmissionDetails$ | async
                           )?.translatedPercentage.toFixed(2)
                        }}%</span
                     >
                  </ng-container>
                  <ng-container *ngIf="!this.highlighQuotes">
                     <span
                        >{{
                           (
                              currentSubmissionDetails$ | async
                           )?.translatedPercentageWithoutQuotes.toFixed(2)
                        }}%</span
                     >
                  </ng-container>
               </ng-container>
               <ng-container *ngIf="crossPlagIndex">
                  <ng-container *ngIf="this.highlighQuotes">
                     <span
                        >{{
                           (
                              currentSubmissionDetails$ | async
                           )?.originalPercentage.toFixed(2)
                        }}%</span
                     >
                  </ng-container>
                  <ng-container *ngIf="!this.highlighQuotes">
                     <span
                        >{{
                           (
                              currentSubmissionDetails$ | async
                           )?.originalPercentageWithoutQuotes.toFixed(2)
                        }}%</span
                     >
                  </ng-container>
               </ng-container>
            </div>
            <p class="report-nav-desktop">
               {{ 'report.report_nav' | translate }}
            </p>
            <div
               class="white-bg navigation report-nav-desktop"
               *ngIf="selectedSources !== true"
               [ngClass]="{ onePage: this.plagiarismLabels.length == 1 }"
            >
               <span
                  [ngClass]="{
                     page_with_plag: this.pages.numberOfSentences !== 0,
                     active_page_with_plag:
                        this.page == i + 1 &&
                        this.pages.numberOfSentences !== 0,
                     scale: 50 > this.plagiarismLabels.length
                  }"
                  *ngFor="let pages of plagiarismLabels; let i = index"
                  (click)="scrollPage(i + 1)"
                  >{{ i + 1 }}</span
               >
            </div>
            <div
               class="white-bg navigation report-nav-desktop selected_pages"
               *ngIf="selectedSources == true"
               [ngClass]="{ onePage: this.plagiarismLabels.length == 1 }"
            >
               <span
                  *ngFor="let page1 of plagiarismLabels; let i = index"
                  (click)="scrollPage(i + 1)"
                  ><span *ngFor="let page_plag of pages">
                     <span
                        [ngClass]="{
                           page_with_plag: page_plag == i + 1,
                           active_page_with_plag:
                              this.page == i + 1 && this.page1.sentences !== 0,
                           scale: 50 > this.plagiarismLabels.length
                        }"
                        *ngIf="page_plag == i + 1"
                     >
                        {{ i + 1 }}
                     </span>
                  </span>
               </span>
            </div>
            <div class="flex border shadow white-bg documents-data">
               <h4>
                  {{ 'general.name' | translate }}:
                  <span>{{ (currentUser$ | async)?.name }} </span>
               </h4>
               <h4
                  class="title"
                  title="{{ (currentSubmissionDetails$ | async)?.title }}"
               >
                  {{ 'report.title' | translate }}:
                  <span class="testIt"
                     ><span class="nameSpan scrollTextAni">{{
                        (currentSubmissionDetails$ | async)?.title
                     }}</span></span
                  >
               </h4>
               <h4>
                  {{ 'general.date' | translate }}:
                  <span>{{
                     (currentSubmissionDetails$ | async)?.createdAt.split(
                        'T'
                     )[0]
                  }}</span>
               </h4>
            </div>
         </div>
      </div>
      <div class="report-nav-mobile">
         <p>{{ 'report.report_nav' | translate }}</p>
         <div
            [ngClass]="{ onePage: this.plagiarismLabels.length == 1 }"
            class="white-bg navigation"
            *ngIf="selectedSources !== true"
         >
            <span
               [ngClass]="{
                  page_with_plag: this.pages.sentences !== 0,
                  active_page_with_plag: this.page == i + 1,
                  scale: 50 > this.plagiarismLabels.length
               }"
               *ngFor="let pages of plagiarismLabels; let i = index"
               (click)="scrollPage(i + 1)"
               >{{ i + 1 }}</span
            >
         </div>
         <div
            class="white-bg navigation selected_pages"
            *ngIf="selectedSources == true"
         >
            <span
               *ngFor="let page1 of plagiarismLabels; let i = index"
               (click)="scrollPage(i + 1)"
               ><span *ngFor="let page_plag of pages">
                  <span
                     [ngClass]="{
                        page_with_plag: page_plag == i + 1,
                        active_page_with_plag:
                           this.page == i + 1 && this.page1.sentences !== 0,
                        scale: 50 > this.plagiarismLabels.length
                     }"
                     *ngIf="page_plag == i + 1"
                  >
                     {{ i + 1 }}
                  </span>
               </span>
            </span>
         </div>
      </div>
      <div
         class="flex report-content pdf"
         [ngClass]="{
            expandedPdf: this.expandedPdf == true
         }"
      >
         <div class="flex column border shadow white-bg">
            <span>
               <span class="file"><i class="far fa-file-alt"></i> </span>
            </span>
            <span>
               <span
                  #Download
                  [ngClass]="{
                     download: downloadClicked,
                     active: downloadClicked
                  }"
                  (click)="download()"
                  ><i class="fas fa-download"></i>
                  <div *ngIf="downloadClicked" class="shadow border">
                     <span
                        style="
                           padding: 10px 15px;
                           border-bottom: 1px solid #d9d9d9;
                        "
                        (click)="downloadPdfReport('mlplag')"
                        >{{documentLanguage}} Originality Report</span
                     >
                     <span
                        style="
                           padding: 10px 5px;
                           border-bottom: 1px solid #d9d9d9;
                        "
                        (click)="downloadPdfReport('crossplag')"
                        *ngIf="translatedLanguage"
                        >{{translatedLanguage}} Originality Report</span
                     >
                     <span
                        *ngIf="this.canEdit"
                        style="
                           padding: 10px 15px;
                           border-bottom: 1px solid #d9d9d9;
                        "
                        (click)="downloadCertificate()"
                     >
                     Originality Certificate
                     </span>
                     <span
                        style="padding: 10px 15px; padding-bottom: 0"
                        (click)="downloadOriginalDocument()"
                        >Document File</span
                     >
                  </div>
                  <div *ngIf="downloadClicked">
                     <span (click)="downloadPdfReport('mlplag')"
                        >Generate Crossplag</span
                     >
                     <span (click)="downloadPdfReport('crossplag')"
                        >Generate MLPlag</span
                     >
                     <span *ngIf="this.canEdit" (click)="downloadCertificate()"
                        >Generate E-certificate</span
                     >

                     <span
                        *ngIf="this.canEdit"
                        (click)="downloadOriginalDocument()"
                        >Original Document</span
                     >
                  </div>
               </span>
            </span>
            <span>
               <span class="flex column"
                  >{{ 'report.page' | translate }}:<span
                     >{{ page }}/{{ documentPages }}</span
                  ></span
               ></span
            >
            <span>
               <span class="flex column"
                  >{{ 'general.words' | translate }}:<span>{{
                     (currentSubmissionDetails$ | async)?.wordCount
                  }}</span></span
               ></span
            >
            <span>
               <span class="flex column"
                  >Language:
                  <span *ngIf="crossPlagIndex"> {{ documentLanguage }}</span>
                  <span *ngIf="MLPlagIndex">{{translatedLanguage}}</span>
               </span>
            </span>
            <span (click)="openDeleteModal()" *ngIf="deleteModal">
               <span  class="flex column file settingBtn"
                  >
                  <i class="far fa-edit"></i>
                  <span class="" >Settings</span>
               </span>
            </span>
            <span (click)="openPermissionsModal()" *ngIf="!deleteModal">
               <span  class="flex column file settingBtn"
                  >
                 <i class="far fa-edit"></i>
                  <span class="" >Settings</span>
               </span>
            </span>
         </div>
         <div style="display: flex; justify-content: center; overflow: hidden">
            <i
               (click)="expandPdf()"
               class="fas fa-times-circle"
               *ngIf="this.expandedPdf == true"
            ></i>
            <div class="expandPdf" (click)="expandPdf()">
               <i
                  class="
                     fas
                     fa-compress-arrows-alt
                     animate__tada animate__animated
                  "
                  *ngIf="this.expandedPdf == true"
               ></i>
               <i
                  class="
                     fas
                     fa-expand-arrows-alt
                     animate__tada animate__animated
                  "
                  *ngIf="this.expandedPdf == false"
               ></i>
            </div>
            <div class="content_wrapper">
               <pdf-viewer
                  id="viewer"
                  (pagechanging)="pageChanging($event)"
                  [src]="pdfSrc"
                  [render-text]="true"
                  [fit-to-page]="true"
                  [page]="selectedPage"
                  (after-load-complete)="callBackFn($event)"
                  (page-rendered)="pageRendered($event)"
                  style="
                     display: block;
                     overflow: auto;
                     height: 100%;
                     scroll-behavior: smooth;
                     border-radius: 20px;
                  "
               ></pdf-viewer>
            </div>
         </div>
      </div>
   </div>
   <div class="flex column">
      <div class="flex border shadow white-bg total-plag similarity-desktop">
         <h3>{{ 'report.similarity' | translate }}</h3>
         <ng-container *ngIf="MLPlagIndex">
            <ng-container *ngIf="this.highlighQuotes">
               <span
                  >{{
                     (
                        currentSubmissionDetails$ | async
                     )?.translatedPercentage.toFixed(2)
                  }}%</span
               >
            </ng-container>
            <ng-container *ngIf="!this.highlighQuotes">
               <span
                  >{{
                     (
                        currentSubmissionDetails$ | async
                     )?.translatedPercentageWithoutQuotes.toFixed(2)
                  }}%</span
               >
            </ng-container>
         </ng-container>
         <ng-container *ngIf="crossPlagIndex">
            <ng-container *ngIf="this.highlighQuotes">
               <span
                  >{{
                     (
                        currentSubmissionDetails$ | async
                     )?.originalPercentage.toFixed(2)
                  }}%</span
               >
            </ng-container>
            <ng-container *ngIf="!this.highlighQuotes">
               <span
                  >{{
                     (
                        currentSubmissionDetails$ | async
                     )?.originalPercentageWithoutQuotes.toFixed(2)
                  }}%</span
               >
            </ng-container>
         </ng-container>
      </div>
      <div class="border shadow white-bg plag-type">
         <div class="flex">
            <a
               [ngClass]="{ active: crossPlagIndex == true }"
               #crossplag
               class="border"
               (click)="getCrossPlag()"
               >{{documentLanguage}}</a
            >
            <a
               [ngClass]="{ active: MLPlagIndex == true }"
               (click)="getMLPlag()"
               class="border"
            >
               {{translatedLanguage?translatedLanguage:'-'}}</a
            >
         </div>
         <h4 *ngIf="!sourcesAreDeleted">
            <i class="fas fa-eye"></i> View :
            <span
               *ngIf="
                  filterSources == false &&
                  topSources == false &&
                  !selectedSources
               "
            >
               {{ 'report.all_sources' | translate }}</span
            >
            <span *ngIf="internetSources == true && !selectedSources">
               {{ 'report.internet_sources' | translate }}</span
            >
            <span *ngIf="topSources == true && !selectedSources">
               {{ 'report.top_three' | translate }}</span
            >
            <span *ngIf="documentSources == true && !selectedSources">
               {{ 'report.document_sources' | translate }}</span
            >
            <span *ngIf="selectedSources">
               {{ 'report.selected_sources' | translate }}</span
            >
            <span (click)="resetFilter()">
               {{ 'report.reset_filter' | translate }}<i class="fas fa-sync"></i
            ></span>
         </h4>
         <div *ngIf="!sourcesAreDeleted" class="flex">
            <h4
               [ngClass]="{
                  active_sources: filterSources == false && topSources == false
               }"
               (click)="getFilteredSources('all')"
            >
               {{ 'report.all_sources' | translate }}
            </h4>
            <h4
               [ngClass]="{
                  active_sources: topSources == true
               }"
               (click)="getFilteredSources('top3')"
            >
               {{ 'report.top_three' | translate }}
            </h4>
            <h4
               [ngClass]="{
                  active_sources: internetSources == true
               }"
               (click)="getFilteredSources(2)"
            >
               {{ 'report.internet_sources' | translate }}
            </h4>
            <h4
               [ngClass]="{
                  active_sources: documentSources == true
               }"
               (click)="getFilteredSources(1)"
            >
               {{ 'report.document_sources' | translate }}
            </h4>
         </div>
      </div>
      <div *ngIf="!sourcesAreDeleted" class="relative">
         <!-- All Sources -->
         <div
            class="sources"
            *ngIf="filterSources == false && topSources == false"
         >
            <div *ngFor="let source of activePlagSources; let i = index">
               <ng-container
                  *ngIf="
                     source.percentage >= 1 &&
                     source.isExcluded == false &&
                     source.isSentenceExcluded === false &&
                     (!source.hide || source.hide == false)
                  "
               >
                  <label [for]="source.sourceId">
                     <div class="flex border shadow white-bg source">
                        <h3>
                           <div class="Checkbox">
                              <input
                                 [id]="source.sourceId"
                                 type="checkbox"
                                 #checkboxes
                                 [checked]="source.isChecked"
                                 (change)="selectSource(source.sourceId)"
                              />
                              <div
                                 [ngClass]="{
                                    'checkbox-visible-def-selected':
                                       defaultSourceSelect
                                 }"
                                 class="Checkbox-visible"
                              ></div>
                           </div>
                        </h3>
                        <div class="flex column">
                           <h4>
                              {{ 'report.source' | translate }}
                              {{ i + 1 }}
                           </h4>
                           <a href="{{ source.sourceUrl }}" target="_blank"
                              >{{ source.sourceUrl }}
                              <p>{{ source.sourceUrl }}</p></a
                           >
                           <ng-container *ngIf="!source.sourceUrl">
                              <i
                                 class="fas fa-file-pdf"
                                 (click)="startDocumentPreview(source.sourceId)"
                              ></i>
                           </ng-container>
                           <h5 (click)="expandRow(i)">
                              {{ 'report.highlight_matches' | translate }}
                           </h5>
                        </div>
                        <span>{{ source.percentage.toFixed(2) }}%</span>
                     </div>
                  </label>
                  <span *ngIf="this.canEdit" (click)="excludeSource(source)"
                     ><i class="fas fa-unlink"></i
                  ></span>
               </ng-container>

               <div
                  class="expanded border shadow white-bg"
                  *ngIf="i == expandedIndex"
               >
                  <div>
                     <i class="fas fa-times-circle" (click)="close()"></i>
                     <h4>{{ 'report.source' | translate }} 77 {{ i + 1 }}</h4>
                     <a href="{{ source.sourceUrl }}" target="_blank"
                        >{{ source.sourceUrl }}
                     </a>
                  </div>
                  <p>
                     {{ source.description }}
                  </p>
               </div>
            </div>
            <div
               *ngIf="lowPercentage(activePlagSources)"
               class="source-box ribbon-sources"
            >
               <h6
                  class="source-box"
                  (click)="toggleFilteredSource()"
                  [ngClass]="{ other_sources: this.revealDiv }"
               >
                  {{ 'report.sources_with_low_plag' | translate }}
               </h6>
               <div *ngFor="let source of activePlagSources; let i = index">
                  <ng-container *ngIf="this.revealDiv">
                     <ng-container
                        *ngIf="!source.hide && !source.isSentenceExcluded"
                     >
                        <ng-container *ngIf="1 > source.percentage">
                           <label [for]="source.sourceId">
                              <div class="flex border shadow white-bg source">
                                 <h3>
                                    <div class="Checkbox">
                                       <input
                                          [id]="source.sourceId"
                                          type="checkbox"
                                          #checkboxes
                                          [checked]="source.isChecked"
                                          (change)="
                                             selectSource(source.sourceId)
                                          "
                                       />

                                       <div
                                          [ngClass]="{
                                             'checkbox-visible-def-selected':
                                                defaultSourceSelect
                                          }"
                                          class="Checkbox-visible"
                                       ></div>
                                    </div>
                                 </h3>
                                 <div class="flex column">
                                    <h4>
                                       {{ 'report.source' | translate }}
                                       {{ i + 1 }}
                                    </h4>
                                    <a
                                       href="{{ source.sourceUrl }}"
                                       target="_blank"
                                       >{{ source.sourceUrl }}</a
                                    >
                                    <h5 (click)="expandRow(i)">
                                       {{
                                          'report.highlight_matches' | translate
                                       }}
                                    </h5>
                                 </div>
                                 <span
                                    >{{ source.percentage.toFixed(2) }}%</span
                                 >
                              </div>
                           </label>
                           <span
                              *ngIf="this.canEdit"
                              (click)="excludeSource(source)"
                              ><i class="fas fa-unlink"></i
                           ></span>
                        </ng-container>
                     </ng-container>
                  </ng-container>
               </div>
            </div>

            <!-- Excluded Sources -->

            <div class="source-box ribbon-sources">
               <h6
                  class="source-box"
                  (click)="toggleExcludedSource()"
                  [ngClass]="{ other_sources: excludedSourcesShow }"
               >
                  Excluded Sources
               </h6>
               <div *ngFor="let source of excludeSources; let i = index">
                  <ng-container *ngIf="excludedSourcesShow">
                     <label [for]="source.sourceId">
                        <div class="flex border shadow white-bg source">
                           <h3>
                              <div class="Checkbox">
                                 <input
                                    [id]="source.sourceId"
                                    type="checkbox"
                                    #checkboxes
                                    [checked]="source.isChecked"
                                    (change)="
                                       highlightExcludedSource(source.sourceId)
                                    "
                                 />

                                 <div
                                    [ngClass]="{
                                       'checkbox-visible-def-selected':
                                          defaultSourceSelect
                                    }"
                                    class="Checkbox-visible"
                                 ></div>
                              </div>
                           </h3>
                           <div class="flex column">
                              <h4>Source {{ source.no }}</h4>
                              <a
                                 href="{{ source.sourceUrl }}"
                                 target="_blank"
                                 >{{ source.sourceUrl }}</a
                              >
                           </div>
                           <span>{{ source.percentage.toFixed(2) }}%</span>
                        </div>
                     </label>
                     <span *ngIf="this.canEdit" (click)="includeSource(source)"
                        ><i class="fas fa-link" style="color: #4c9dbb"></i
                     ></span>
                  </ng-container>
               </div>
            </div>

            <!-- Excluded Sources -->
         </div>
         <!-- All Sources -->

         <!-- Filtered Sources (internet or documents) -->
         <div class="sources" *ngIf="filterSources == true">
            <div *ngFor="let source of filteredSources; let i = index">
               <ng-container
                  *ngIf="source.percentage >= 1 && source.isExcluded == false"
               >
                  <label [for]="source.sourceId">
                     <div class="flex border shadow white-bg source">
                        <h3>
                           <div class="Checkbox">
                              <input
                                 [id]="source.sourceId"
                                 type="checkbox"
                                 (change)="selectSource(source.sourceId)"
                                 [checked]="source.isChecked"
                                 #checkboxes
                              />

                              <div
                                 [ngClass]="{
                                    'checkbox-visible-def-selected':
                                       defaultSourceSelect
                                 }"
                                 class="Checkbox-visible"
                              ></div>
                           </div>
                        </h3>
                        <div class="flex column">
                           <h4>
                              {{ 'report.source' | translate }}
                              {{ i + 1 }}
                           </h4>
                           <a href="{{ source.sourceUrl }}" target="_blank"
                              >{{ source.sourceUrl }}
                              <p>{{ source.sourceUrl }}</p></a
                           >
                           <h5 (click)="expandRow(i)">
                              {{ 'report.highlight_matches' | translate }}
                           </h5>
                        </div>
                        <span>{{ source.percentage.toFixed(2) }}%</span>
                     </div>
                  </label>
                  <span *ngIf="this.canEdit" (click)="excludeSource(source)"
                     ><i class="fas fa-unlink"></i
                  ></span>
                  <div
                     class="expanded border shadow white-bg"
                     *ngIf="i == expandedIndex"
                  >
                     <i class="fas fa-times-circle" (click)="close()"></i>
                     {{ source.description }}
                  </div>
               </ng-container>
            </div>
            <!-- Sources with less thane 1% -->
            <div
               *ngIf="lowPercentage(filteredSources)"
               class="source-box ribbon-sources"
            >
               <h6
                  class="source-box"
                  (click)="toggleFilteredSource()"
                  [ngClass]="{ other_sources: this.revealDiv }"
               >
                  {{ 'report.sources_with_low_plag' | translate }}
               </h6>
               <div *ngFor="let source of filteredSources; let i = index">
                  <ng-container *ngIf="this.revealDiv">
                     <ng-container
                        *ngIf="
                           1 > source.percentage && source.isExcluded == false
                        "
                     >
                        <label [for]="source.sourceId">
                           <div class="flex border shadow white-bg source">
                              <h3>
                                 <div class="Checkbox">
                                    <input
                                       [id]="source.sourceId"
                                       type="checkbox"
                                       #checkboxes
                                       [checked]="source.isChecked"
                                       (change)="selectSource(source.sourceId)"
                                    />

                                    <div
                                       [ngClass]="{
                                          'checkbox-visible-def-selected':
                                             defaultSourceSelect
                                       }"
                                       class="Checkbox-visible"
                                    ></div>
                                 </div>
                              </h3>
                              <div class="flex column">
                                 <h4>Source {{ source.no }}</h4>
                                 <a
                                    href="{{ source.sourceUrl }}"
                                    target="_blank"
                                    >{{ source.sourceUrl }}</a
                                 >
                                 <!-- <h5 (click)="expandRow(i)">
                                 highlight matches in source
                              </h5> -->
                              </div>
                              <span>{{ source.percentage.toFixed(2) }}%</span>
                           </div>
                        </label>
                     </ng-container>
                  </ng-container>
               </div>
            </div>
            <!-- Sources with less thane 1% -->
            <!-- Excluded Sources -->
            <div class="source-box ribbon-sources">
               <h6
                  class="source-box"
                  (click)="toggleExcludedSource()"
                  [ngClass]="{ other_sources: excludedSourcesShow }"
               >
                  Excluded Sources
               </h6>
               <div *ngFor="let source of filteredSources; let i = index">
                  <ng-container *ngIf="excludedSourcesShow">
                     <ng-container *ngIf="source.isExcluded == true">
                        <label [for]="source.sourceId">
                           <div class="flex border shadow white-bg source">
                              <h3>
                                 <div class="Checkbox">
                                    <input
                                       [id]="source.sourceId"
                                       type="checkbox"
                                       #checkboxes
                                       [checked]="source.isChecked"
                                       (change)="selectSource(source.sourceId)"
                                    />

                                    <div
                                       [ngClass]="{
                                          'checkbox-visible-def-selected':
                                             defaultSourceSelect
                                       }"
                                       class="Checkbox-visible"
                                    ></div>
                                 </div>
                              </h3>
                              <div class="flex column">
                                 <h4>
                                    {{ 'report.source' | translate }}
                                    {{ i + 1 }}
                                 </h4>
                                 <a
                                    href="{{ source.sourceUrl }}"
                                    target="_blank"
                                    >{{ source.sourceUrl }}</a
                                 >
                                 <h5 (click)="expandRow(i)">
                                    {{ 'report.highlight_matches' | translate }}
                                 </h5>
                              </div>
                              <span>{{ source.percentage.toFixed(2) }}%</span>
                           </div>
                        </label>
                        <span
                           *ngIf="this.canEdit"
                           (click)="includeSource(source)"
                           ><i class="fas fa-link" style="color: #4c9dbb"></i
                        ></span>
                     </ng-container>
                  </ng-container>
               </div>
            </div>
            <!-- Excluded Sources -->
         </div>
         <!-- Filtered Sources (internet or documents) -->

         <!-- Top Sources -->
         <div class="sources" *ngIf="topSources == true">
            <div *ngFor="let source of topThreeSources; let i = index">
               <ng-container
                  *ngIf="source.percentage >= 1 && source.isExcluded == false"
               >
                  <label [for]="source.sourceId">
                     <div class="flex border shadow white-bg source">
                        <h3>
                           <div class="Checkbox">
                              <input
                                 [id]="source.sourceId"
                                 type="checkbox"
                                 (change)="selectSource(source.sourceId)"
                                 [checked]="source.isChecked"
                                 #checkboxes
                              />

                              <div
                                 [ngClass]="{
                                    'checkbox-visible-def-selected':
                                       defaultSourceSelect
                                 }"
                                 class="Checkbox-visible"
                              ></div>
                           </div>
                        </h3>
                        <div class="flex column">
                           <h4>
                              {{ 'report.source' | translate }}
                              {{ i + 1 }}
                           </h4>
                           <a href="{{ source.sourceUrl }}" target="_blank"
                              >{{ source.sourceUrl }}
                              <p>{{ source.sourceUrl }}</p></a
                           >
                           <h5 (click)="expandRow(i)">
                              {{ 'report.highlight_matches' | translate }}
                           </h5>
                        </div>
                        <span>{{ source.percentage.toFixed(2) }}%</span>
                     </div>
                  </label>
                  <span *ngIf="this.canEdit" (click)="excludeSource(source)"
                     ><i class="fas fa-unlink"></i
                  ></span>
                  <div
                     class="expanded border shadow white-bg"
                     *ngIf="i == expandedIndex"
                  >
                     <i class="fas fa-times-circle" (click)="close()"></i>
                     {{ source.description }}
                  </div>
               </ng-container>
            </div>
            <!-- Sources Less than 1 % -->
            <div
               *ngIf="lowPercentage(topThreeSources)"
               class="source-box ribbon-sources"
            >
               <h6
                  class="source-box"
                  (click)="toggleFilteredSource()"
                  [ngClass]="{ other_sources: this.revealDiv }"
               >
                  {{ 'report.sources_with_low_plag' | translate }}
               </h6>
               <div *ngFor="let source of topThreeSources; let i = index">
                  <ng-container *ngIf="this.revealDiv">
                     <ng-container *ngIf="1 > source.percentage">
                        <label [for]="source.sourceId">
                           <div class="flex border shadow white-bg source">
                              <h3>
                                 <div class="Checkbox">
                                    <input
                                       [id]="source.sourceId"
                                       type="checkbox"
                                       #checkboxes
                                       [checked]="source.isChecked"
                                       (change)="selectSource(source.sourceId)"
                                    />

                                    <div
                                       [ngClass]="{
                                          'checkbox-visible-def-selected':
                                             defaultSourceSelect
                                       }"
                                       class="Checkbox-visible"
                                    ></div>
                                 </div>
                              </h3>
                              <div class="flex column">
                                 <h4>
                                    {{ 'report.source' | translate }}
                                    {{ i + 1 }}
                                 </h4>
                                 <a
                                    href="{{ source.sourceUrl }}"
                                    target="_blank"
                                    >{{ source.sourceUrl }}</a
                                 >
                                 <h5 (click)="expandRow(i)">
                                    {{ 'report.highlight_matches' | translate }}
                                 </h5>
                              </div>
                              <span>{{ source.percentage.toFixed(2) }}%</span>
                           </div>
                        </label>
                     </ng-container>
                  </ng-container>
               </div>
            </div>
            <!-- Sources Less than 1 % -->

            <!-- Excluded Sources -->
            <div class="source-box ribbon-sources">
               <h6
                  class="source-box"
                  (click)="toggleExcludedSource()"
                  [ngClass]="{ other_sources: excludedSourcesShow }"
               >
                  Excluded Sources
               </h6>
               <div *ngFor="let source of topThreeSources; let i = index">
                  <ng-container *ngIf="excludedSourcesShow">
                     <ng-container *ngIf="source.isExcluded == true">
                        <label [for]="source.sourceId">
                           <div class="flex border shadow white-bg source">
                              <h3>
                                 <div class="Checkbox">
                                    <input
                                       [id]="source.sourceId"
                                       type="checkbox"
                                       #checkboxes
                                       [checked]="source.isChecked"
                                       (change)="selectSource(source.sourceId)"
                                    />

                                    <div
                                       [ngClass]="{
                                          'checkbox-visible-def-selected':
                                             defaultSourceSelect
                                       }"
                                       class="Checkbox-visible"
                                    ></div>
                                 </div>
                              </h3>
                              <div class="flex column">
                                 <h4>Source {{ source.no }}</h4>
                                 <a
                                    href="{{ source.sourceUrl }}"
                                    target="_blank"
                                    >{{ source.sourceUrl }}</a
                                 >
                                 <!-- <h5 (click)="expandRow(i)">
                                    highlight matches in source
                                 </h5> -->
                              </div>
                              <span>{{ source.percentage.toFixed(2) }}%</span>
                           </div>
                        </label>
                        <span
                           *ngIf="this.canEdit"
                           (click)="includeSource(source)"
                           ><i class="fas fa-link" style="color: #4c9dbb"></i
                        ></span>
                     </ng-container>
                  </ng-container>
               </div>
            </div>
            <!-- Excluded Sources -->
         </div>
         <!-- Top Sources -->
      </div>
      <div class="relative white-bg deletedSourcesContainer" *ngIf="sourcesAreDeleted">
         <span *ngIf="!canEdit || (currentUserDetails.roleId == 2 && currentUserDetails.institutionId == null)">You have deleted sources <br> for this document</span>
         <span *ngIf="canEdit && currentUserDetails.roleId != 2 && currentUserDetails.institutionId != null">Student has deleted sources <br> for this document</span>
       </div>
      <div class="border shadow white-bg legend desktop-legend">
         <h4>{{ 'report.legend_highlight' | translate }}</h4>
         <div>
            <div>
               <img src="../../../assets/images/Exact%20Match.png" alt="" />
               <h3>{{ 'report.exact_match' | translate }}</h3>
            </div>
            <div>
               <img src="../../../assets/images/Possibly%20Alterd.png" alt="" />
               <h3>{{ 'report.possibly_altered_text' | translate }}</h3>
            </div>
            <div>
               <img src="../../../assets/images/Qoutation.png" alt="" />
               <h3>{{ 'report.qoutation' | translate }}</h3>
               <label class="switch" *ngIf="this.canEdit">
                  <input
                     [checked]="highlighQuotes"
                     type="checkbox"
                     (change)="toggleQuotes()"
                  />

                  <span class="slider round"></span>
               </label>
            </div>
            <div>
               <img src="../../../assets/images/Removed%20Text.png" alt="" />
               <h3>{{ 'report.removed' | translate }}</h3>
            </div>
         </div>
      </div>
   </div>
   <div [ngClass]="{ 'show-scrollTop': windowScrolled }" class="to-top">
      <button (click)="scrollPageToTop()">
         <i class="fas fa-arrow-circle-up"></i>
      </button>
   </div>
   <div class="border shadow white-bg legend mobile-legend">
      <h4>{{ 'report.legend_highlight' | translate }}</h4>
      <div>
         <div>
            <img src="../../../assets/images/Exact%20Match.png" alt="" />
            <h3>{{ 'report.exact_match' | translate }}</h3>
         </div>
         <div>
            <img src="../../../assets/images/Possibly%20Alterd.png" alt="" />
            <h3>{{ 'report.possibly_altered_text' | translate }}</h3>
         </div>
         <div>
            <img src="../../../assets/images/Qoutation.png" alt="" />
            <h3>{{ 'report.qoutation' | translate }}</h3>
            <label class="switch" *ngIf="this.canEdit">
               <input type="checkbox" />

               <span class="slider round"></span>
            </label>
         </div>
         <div>
            <img src="../../../assets/images/Removed%20Text.png" alt="" />
            <h3>{{ 'report.removed' | translate }}</h3>
         </div>
      </div>
   </div>
</div>

<h1 class="report-info-text" *ngIf="showText">Generating Offline Report</h1>
