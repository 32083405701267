<section class="flex register-profesori container">
   <main>
      <h4 class="bold">Register subjects</h4>
      <div class="nav">
         <a (click)="toggleForm('manual')" [ngClass]="{ active: this.excel === false }"
            >Manual form</a
         >
         <a (click)="toggleForm('bulk')" [ngClass]="{ active: this.excel === true }" class="disable"
            >Bulk form</a
         >
      </div>
      <ng-container *ngIf="this.excel === false">
         <form action="" [formGroup]="subjectRegister">
            <div formArrayName="subject">
               <ng-container
                  *ngFor="let subject of subjectForm.controls; let i = index"
                  [formGroupName]="i"
               >
                  <h5>Subject name</h5>
                  <input formControlName="subjectName" type="text" />
                  <div
                     *ngIf="
                        subjectForm.controls[i].controls.subjectName.touched &&
                        subjectForm.controls[i].controls.subjectName.invalid
                     "
                     class="invalid"
                  >
                     Subject Name is required*
                  </div>
                  <div
                     *ngIf="
                        subjectForm.controls[i].controls.subjectName.errors
                           ?.minlength
                     "
                     class="invalid"
                  >
                     Subject name should contain at least 3 characters!
                  </div>
                  <h5>Faculty</h5>
                  <select
                     formControlName="subjectFaculty"
                     (change)="facultyChanged(i)"
                  >
                     <option value="" selected disabled>Select faculty</option>

                     <ng-container
                        *ngFor="let faculty of this.facultiesDetails"
                     >
                        <option value="{{ faculty.id }}">
                           {{ faculty.name }}
                        </option>
                     </ng-container>
                  </select>
                  <div
                     *ngIf="
                        subjectForm.controls[i].controls.subjectFaculty
                           .touched &&
                        subjectForm.controls[i].controls.subjectFaculty.invalid
                     "
                     class="invalid"
                  >
                     Subject Faculty is required*
                  </div>
                  <h5>Department</h5>
                  <select
                     disabled
                     *ngIf="
                        subjectForm.controls[i].controls.subjectFaculty.value ==
                           '' ||
                        subjectForm.controls[i].controls.subjectFaculty.value ==
                           undefined
                     "
                     name=""
                     id=""
                  >
                     <option value="">Select faculty first</option>
                  </select>
                  <select
                     *ngIf="
                        subjectForm.controls[i].controls.subjectFaculty
                           .value !== '' &&
                        subjectForm.controls[i].controls.subjectFaculty
                           .value !== undefined
                     "
                     formControlName="subjectDepartment"
                     (change)="departmentSelected(i, $event.target.value)"
                  >
                     <option value="" selected disabled>
                        Select Department
                     </option>

                     <ng-container
                        *ngFor="let department of this.departments[i]"
                     >
                        <option value="{{ department.id }}">
                           {{ department.name }}
                        </option>
                     </ng-container>
                  </select>
                  <div
                     *ngIf="
                        subjectForm.controls[i].controls.subjectDepartment
                           .touched &&
                        subjectForm.controls[i].controls.subjectDepartment
                           .invalid
                     "
                     class="invalid"
                  >
                     Subject Department is required*
                  </div>
                  <h5>Description</h5>
                  <input formControlName="subjectDescription" type="text" />
                  <div
                     *ngIf="
                        subjectForm.controls[i].controls.subjectDescription
                           .touched &&
                        subjectForm.controls[i].controls.subjectDescription
                           .invalid
                     "
                     class="invalid"
                  >
                     Subject Description is required*
                  </div>
                  <div
                     *ngIf="
                        subjectForm.controls[i].controls.subjectDescription
                           .errors?.minlength
                     "
                     class="invalid"
                  >
                     Subject Description should contain at least 3 characters!
                  </div>
                  <h5>Study year</h5>
                  <select formControlName="subjectYear">
                     <option value="" selected disabled>Choose year!</option>
                     <ng-container
                        *ngFor="let year of this.subjectForm.controls[i].controls.subjectYears.value"
                     >
                        <option value="{{ year.index }}">
                           {{ year.index }}
                        </option>
                     </ng-container>
                  </select>
                  <div
                     *ngIf="
                        subjectForm.controls[i].controls.subjectYear.touched &&
                        subjectForm.controls[i].controls.subjectYear.invalid
                     "
                     class="invalid"
                  >
                     This field is required.
                  </div>
                  <h5>ID</h5>
                  <input
                     formControlName="subjectCode"
                     (ngModelChange)="
                        validateCode(
                           subjectForm.controls[i].controls.subjectCode,
                           i
                        )
                     "
                     type="text"
                  />
                  <mat-spinner *ngIf="showSpinner == i"></mat-spinner>
                  <div *ngIf="this.errors.includes(i)" class="invalid">

                     Subject Code already exists, please try another one.*
                  </div>
                  <div *ngIf="this.sameCodes.includes(i)" class="invalid">
                     Subject Code already exists, please try another one.*
                  </div>
                  <div
                     *ngIf="
                        subjectForm.controls[i].controls.subjectCode.touched &&
                        subjectForm.controls[i].controls.subjectCode.invalid
                     "
                     class="invalid"
                  >
                     Subject ID is required*
                  </div>
                  <div
                     *ngIf="
                        subjectForm.controls[i].controls.subjectCode.errors
                           ?.minlength
                     "
                     class="invalid"
                  >
                     Subject ID should contain at least 3 characters!
                  </div>
                  <h5>Type</h5>
                  <select
                     class="border white-bg select-adm"
                     formControlName="subjectType"
                  >
                     <option value="-1" selected disabled>
                        Choose subject type!
                     </option>
                     <option value="1">Mandatory</option>
                     <option value="2">Elective</option>
                  </select>
                  <div
                     *ngIf="
                        subjectForm.controls[i].controls.subjectType.touched &&
                        subjectForm.controls[i].controls.subjectType.invalid
                     "
                     class="invalid"
                  >
                     Subject Type is required*
                  </div>
                  <h5>Academic Year</h5>
                  <select
                     class="border white-bg select-adm"
                     formControlName="subjectGenerate"
                  >
                     <option value="-1" selected disabled>
                        Select the academic year.
                     </option>
                     <option value="g1819">2018/2019</option>
                     <option value="g1920">2019/2020</option>
                     <option value="g2021">2020/2021</option>
                     <option value="g2122">2021/2022</option>
                     <option value="g2223">2022/2023</option>
                     <option value="g2324">2023/2024</option>
                     <option value="g2425">2024/2025</option>
                     <option value="g2526">2025/2026</option>
                  </select>
                  <div
                     *ngIf="
                        subjectForm.controls[i].controls.subjectGenerate
                           .touched &&
                        subjectForm.controls[i].controls.subjectGenerate.invalid
                     "
                     class="invalid"
                  >
                     Subject Generation is required*
                  </div>
                  <ng-container *ngIf="subjectForm.controls.length > 1">
                     <div class="right">
                        <button class="remove" (click)="this.removeSubject(i)">
                           -remove
                        </button>
                     </div>
                  </ng-container>
               </ng-container>
            </div>
            <button (click)="this.addSubject()" class="addMore">
               + Add a new subject
            </button>
            <div class="center">
               <button
                  [disabled]="subjectForm.invalid || this.codeExists"
                  [ngClass]="{
                     disable: subjectForm.invalid || this.codeExists
                  }"
                  class="submit"
                  type="submit"
                  (click)="submit()"
               >
                  Confirm
               </button>
            </div>
         </form>
      </ng-container>
      <ng-container *ngIf="this.excel === true">
         <div class="content">
            <div>
               <select
                  (change)="facultyChanged(0)"
                  [(ngModel)]="this.selectedFaculty"
               >
                  <option selected disabled value="">Select faculty</option>
                  <ng-container *ngFor="let faculty of this.facultiesDetails">
                     <option value="{{ faculty.id }}">
                        {{ faculty.name }}
                     </option>
                  </ng-container>
               </select>
               <div
                  class="border"
                  (click)="downloadTemplate()"
                  style="cursor: pointer"
               >
                  <h6>Download Template</h6>
                  <div>
                     <i class="fas fa-download"></i>
                     <p>
                        Download and fill the relevant information within the
                        template
                     </p>
                  </div>
               </div>
            </div>
            <div>
               <select
                  disabled
                  *ngIf="
                     this.selectedFaculty == '' ||
                     this.selectedFaculty == undefined
                  "
               >
                  <option value="">Select faculty first</option>
               </select>
               <select
                  *ngIf="
                     this.selectedFaculty !== '' &&
                     this.selectedFaculty !== undefined
                  "
                  [(ngModel)]="this.selectedDepartment"
               >
                  <option selected disabled value="">Select department</option>
                  <ng-container *ngFor="let department of this.departments[0]">
                     <option value="{{ department.id }}">
                        {{ department.name }}
                     </option>
                  </ng-container>
               </select>
               <div
                  class="border"
                  (click)="
                     this.selectedFaculty !== undefined &&
                     this.selectedDepartment !== undefined
                        ? upload()
                        : showAlert()
                  "
                  style="cursor: pointer"
               >
                  <div>
                     <h6>Upload Template</h6>
                     <i class="fas fa-upload"></i>
                  </div>
                  <p>
                     Upload the filled template to migrate your data
                     automatically
                  </p>
                  <input
                     class="dropzone"
                     type="file"
                     name=""
                     id=""
                     (change)="onFileSelected($event)"
                     accept=".xlsx"
                     style="display: none"
                  />
               </div>
            </div>
         </div>
      </ng-container>
      <ng-container *ngIf="this.excel === true && this.showErrors">
         <div class="content">
            <table>
               <tr>
                  <th>
                     <h5 class="bold">#</h5>
                  </th>
                  <th>
                     <h5 class="bold">Error</h5>
                  </th>
                  <th>
                     <h5 class="bold">Mark as fixed</h5>
                  </th>
               </tr>
               <tr *ngFor="let error of this.errorFromExcel; let i = index">
                  <td>
                     <h5>
                        {{ i + 1 }}
                     </h5>
                  </td>
                  <td>
                     <h5 id="{{ i + 1 }}">
                        {{ error }}
                     </h5>
                  </td>
                  <td>
                     <h5 (click)="changeStyle(i + 1)" style="cursor: pointer">
                        Mark
                     </h5>
                  </td>
               </tr>
            </table>
         </div>
      </ng-container>
   </main>
</section>
