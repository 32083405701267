<div>
   <img src="../../assets/images/Crossplag_Logo (3).png" alt="" />
   <ng-container>
      <main>
         <h3>Set a password to activate your account</h3>
         <form action="" [formGroup]="setPasswordForm">
            <ng-container *ngIf="invited">
               <h5>Name</h5>
               <div class="password">
                  <input type="text" formControlName="name">
                  <div class="invalid" *ngIf="form.name.touched && form.name.errors.required">
                     Name is required*
                  </div>
               </div>
               <h5>Index (optional)</h5>
               <div class="password">
                  <input type="text" formControlName="index">
               </div>
            </ng-container>
            <h5>Password</h5>
            <div class="password">
               <input
                  [type]="showPassword ? 'text' : 'password'"
                  name=""
                  id=""
                  formControlName="password"
                  (keyup)="checkPassword()"
               />
               <span class="input-group-text" (click)="password()">
                  <i
                     class="fa"
                     [ngClass]="{
                        'fa-eye-slash': !showPassword,
                        'fa-eye': showPassword
                     }"
                  ></i>
               </span>
            </div>
            <!-- <div class="password" style="display: grid; text-align: left">
               <span>
                  <ng-container *ngIf="this.lowerCase === false">
                     <i style="color: #e03243" class="fas fa-times"></i>
                  </ng-container>
                  <ng-container *ngIf="this.lowerCase === true">
                     <i style="color: #71c490" class="fas fa-check"></i>
                  </ng-container>
                  Password must contain Lower Case character (a-z)</span
               >
               <span>
                  <ng-container *ngIf="this.upperCase === false">
                     <i style="color: #e03243" class="fas fa-times"></i>
                  </ng-container>
                  <ng-container *ngIf="this.upperCase === true">
                     <i style="color: #71c490" class="fas fa-check"></i>
                  </ng-container>
                  Password must contain Upper Case character (A-Z)</span
               >
               <span>
                  <ng-container *ngIf="this.numeric === false">
                     <i style="color: #e03243" class="fas fa-times"></i>
                  </ng-container>
                  <ng-container *ngIf="this.numeric === true">
                     <i style="color: #71c490" class="fas fa-check"></i>
                  </ng-container>
                  Password must contain numeric value (0-9)</span
               >
               <span>
                  <ng-container *ngIf="this.specialCharacter === false">
                     <i style="color: #e03243" class="fas fa-times"></i>
                  </ng-container>
                  <ng-container *ngIf="this.specialCharacter === true">
                     <i style="color: #71c490" class="fas fa-check"></i>
                  </ng-container>
                  Password must contain special character (@!*)</span
               >
               <span>
                  <ng-container
                     *ngIf="this.setPasswordForm.value.password.length < 8"
                  >
                     <i style="color: #e03243" class="fas fa-times"></i>
                  </ng-container>
                  <ng-container
                     *ngIf="this.setPasswordForm.value.password.length >= 8"
                  >
                     <i style="color: #71c490" class="fas fa-check"></i>
                  </ng-container>
                  Password must be at least 8 characters</span
               >
            </div> -->
            <div
               *ngIf="form.password.invalid && form.password.touched"
               class="invalid"
            >
               <div *ngIf="form.password.errors.required">
                  password is required*
               </div>
               <div *ngIf="form.password.errors.pattern">
                  Must be 8 characters long, at least 1 number, symbol, and uppercase letter
               </div>
            </div>

            <h5>Confirm password</h5>
            <div class="password">
               <input
                  [type]="showConfirmPassword ? 'text' : 'password'"
                  name=""
                  id=""
                  formControlName="confirmPassword"
               />
               <span class="input-group-text" (click)="confirmPassword()">
                  <i
                     class="fa"
                     [ngClass]="{
                        'fa-eye-slash': !showConfirmPassword,
                        'fa-eye': showConfirmPassword
                     }"
                  ></i>
               </span>
            </div>
            <div
               *ngIf="
                  form.confirmPassword.invalid && form.confirmPassword.touched
               "
               class="invalid"
            >
               <div *ngIf="form.confirmPassword.errors.required">
                  confirm New Password is required*
               </div>
            </div>
            <div
               class="invalid"
               *ngIf="
                  form.confirmPassword.value !== form.password.value &&
                  form.confirmPassword.touched &&
                  form.confirmPassword.value !== ''
               "
            >
               Passwords don't match.
            </div>
            <div class="terms">
               <input
                  name="terms_and_conditions"
                  id="terms_and_conditions"
                  type="checkbox"
                  formControlName="agree"
               />
               <label for="terms_and_conditions"></label>
               <a mat-button (click)="openModal()">
                  {{ 'general.terms_and_conditions' | translate }}</a
               >
            </div>

            <div class="center">
               <button
                  [disabled]="
                     setPasswordForm.invalid ||
                     form.password.value !== form.confirmPassword.value ||
                     form.confirmPassword.value == '' ||
                     !form.agree.value
                  "
                  [ngClass]="{
                     disable:
                        setPasswordForm.invalid ||
                        form.password.value !== form.confirmPassword.value ||
                        form.confirmPassword.value == '' ||
                        !form.agree.value
                  }"
                  class="submit"
                  type="submit"
                  (click)="submit()"
               >
                  Submit
               </button>
            </div>
         </form>
      </main>
   </ng-container>
</div>
